import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout__wrapper" }
const _hoisted_2 = { class: "checkout__content" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced g-row--space_2xl-xl" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_7 = { class: "g-row g-row--appearance_spaced g-row--space_2xl-xl" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkout", {'is-active': $setup.isActive}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
    ]), 2),
    _createElementVNode("div", {
      class: _normalizeClass(["blur", {'is-hidden': !$setup.isActive}]),
      onClick: _withModifiers($setup.hideOrder, ["prevent"])
    }, null, 2),
    _createVNode(_component_VeeForm, { onSubmit: $setup.send }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode($setup["CheckoutProducts"], {
                  cart: $setup.cartStore.cartInfo
                }, null, 8, ["cart"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["CheckoutUserInfo"], {
                  formdata: $setup.formdata,
                  errors: errors,
                  choices: $setup.choices,
                  "onSettlement:search": $setup.searchSettlement,
                  "onArea:changed": $setup.areaChangeHandler
                }, null, 8, ["formdata", "errors", "choices"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["CheckoutPayment"], {
                  formdata: $setup.formdata,
                  items: $setup.props.gateways
                }, null, 8, ["formdata", "items"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["checkout__aside", {'is-active': $setup.isActive}])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode($setup["Promocode"], {
                  promocode: $setup.cartStore.cartInfo.promocode,
                  onApplyPromocode: _cache[0] || (_cache[0] = ($event: any) => ($setup.refreshCartInfo()))
                }, null, 8, ["promocode"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode($setup["CheckoutSummary"], {
                  formdata: $setup.formdata,
                  cart: $setup.cartStore.cartInfo
                }, null, 8, ["formdata", "cart"])
              ])
            ])
          ], 2)
        ])
      ]),
      _: 1
    })
  ], 2))
}