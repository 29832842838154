const messageGetter = x => {
  if (x.message) return x.message

  if (x.zCode) return x.zCode.map(messageGetter)
}

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter)

    return acc
  }, {})
}

export async function useErrorsSetter(e, setFieldError) {
  const parsed = await useErrorsGetter(e)

  useParsedSetter(parsed, setFieldError)
}

export const useParsedSetter = (parsed, setFieldError) => {
  Object.keys(parsed).forEach(key => {
    setFieldError(key, parsed[key][0])
  })
}

export async function useErrorsGetter(e) {
  const errors = {}

  await e.json().then(body => {
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
  })

  const parsed = defaultValidatorErrorsParser(errors)

  return parsed
}

export async function useSimpleErrorsGetter(e) {
  const errors = {}

  await e.json().then(body => {
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
  })

  return errors
}

export const scrollToError = ({ errors }) => {
  const e = Object.keys(errors)[0]
  const el = document.querySelector(`.d-control-input__label[data-field="${e}"]`)

  if (el) {
    el.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

export function useSubmit(send, control) {
  function catchFormErrors(promise, controller) {
    return promise.catch(e => {
      const clientError = 400
      const serverError = 500
      if (!e.status || clientError > e.status || serverError <= e.status) {
        throw e
      }
  
      if (!updateValidator) {
        return e
      }
  
      return e.json().then(body => {
        const errors = {}
  
        body.errors.forEach(error => {
          if ('request' === error.domain && error.state) {
            Object.assign(errors, error.state)
          }
        })
  
        updateValidator(errors, controller)
  
        return e
      })
    })
  }
  
  function updateValidator(errors, controller) {
    controller.setFieldError('nonFieldErrors', '')

    const parsed = defaultValidatorErrorsParser(errors)

    useParsedSetter(parsed, controller.setFieldError)
  }
  
  function submit(send, control) {
    return catchFormErrors(send, control)
  }

  submit(send, control)
}
