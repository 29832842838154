import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'
import { CURRENT_LANGUAGE, prefixLanguage } from '@/utils/urls'
import useGlobal from '@/composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSwitch',
  setup(__props, { expose: __expose }) {
  __expose();

const { $t } = useGlobal()

const languages = [
  {
    value: 'en',
    label: $t('Eng'),
    icon: '/static/app/images/en.png',
  },
  {
    value: 'uk',
    label: $t('Ua'),
    icon: '/static/app/images/ua.png',
  },
]

const currentLanguage = computed(() => {
  return languages.find(el => el.value === CURRENT_LANGUAGE) || {}
})

const getLanguageLink = (language: string) => prefixLanguage(window.location.href, { language })

const __returned__ = { $t, languages, currentLanguage, getLanguageLink, computed, get CURRENT_LANGUAGE() { return CURRENT_LANGUAGE }, get prefixLanguage() { return prefixLanguage }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})