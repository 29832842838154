import { App } from 'vue'
import VueViewer from 'v-viewer'

export default {
  install(app: App<Element>): any {
    app.use(VueViewer, {
      defaultOptions: {
        title: false,
        transition: false,
        toolbar: {
          play: false,
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: true,
          prev: true,
          next: true,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,
        },
      },
    })
  }
}
