import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch, nextTick } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { feedbackCreateResource } from '@/services/feedback.service'
import { useShowModal } from '@/composables/useModalOpener'
import { useUserStore } from '@/store/user'
import useGlobal from '@/composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'Feedback',
  setup(__props, { expose: __expose }) {
  __expose();

const { $t } = useGlobal()
const userStore = useUserStore()
const isLoad = ref(false)

const formdata = reactive({
  firstName: '',
  lastName: '',
  email: '',
  pageFrom: window.location.href
})

const prefillUserInfo = val => {
  if (!window.isAuthenticated) return

  formdata.firstName = val.info.firstName
  formdata.lastName = val.info.lastName
  formdata.email = val.info.email
}

watch(() => userStore.getUserInfo, val => {
  prefillUserInfo(val)
}, { immediate: true, deep: true })

const resetForm = () => {
  prefillUserInfo(userStore.getUserInfo)
}

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  preparedFormdata.name = `${preparedFormdata.firstName} ${preparedFormdata.lastName}`

  delete preparedFormdata.firstName
  delete preparedFormdata.lastName

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  feedbackCreateResource.execute({}, preparedFormdata).then(() => {
    const message = {
      title: $t('Спасибо за запрос'),
      text: $t('Ваша заявка принята'),
    }

    useShowModal(message)

    control.resetForm()

    nextTick(() => {
      resetForm()
    })
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

const __returned__ = { $t, userStore, isLoad, formdata, prefillUserInfo, resetForm, prepareFormdata, send, ref, reactive, watch, nextTick, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get feedbackCreateResource() { return feedbackCreateResource }, get useShowModal() { return useShowModal }, get useUserStore() { return useUserStore }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})