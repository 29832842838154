import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { Autoplay, Pagination, Navigation } from 'swiper'
import { useWindowSize } from '@vueuse/core'
import { debounce } from 'lodash'

const IS_DESKTOP = 1200


export default /*@__PURE__*/_defineComponent({
  __name: 'Information',
  setup(__props, { expose: __expose }) {
  __expose();

const modules = [Autoplay, Pagination, Navigation]

const swiperEl = ref()
const sliderKey = ref(0)
const isMounted = ref(false)

const onSwiper = (swiper: any) => {
  swiperEl.value = swiper

  if (!isMounted.value) {
    debounceUpdate()
  }

  isMounted.value = true
}

const { width } = useWindowSize()

const toggleSwiper = () => {
  if (!swiperEl.value?.destroy) return

  if (width.value >= IS_DESKTOP) {
    swiperEl.value.destroy(true, true)
  } else {
    sliderKey.value += 1
  }
}

const debounceUpdate = debounce(toggleSwiper, 200)

watch(() => width.value, () => {
  debounceUpdate()
})

const __returned__ = { modules, swiperEl, sliderKey, isMounted, IS_DESKTOP, onSwiper, width, toggleSwiper, debounceUpdate, ref, watch, get Autoplay() { return Autoplay }, get Pagination() { return Pagination }, get Navigation() { return Navigation }, get useWindowSize() { return useWindowSize }, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})