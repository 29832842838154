import { App } from 'vue'
import View from './View.vue'

const register = (app: App<Element>): void => {
  app
    .component('checkout-view', View)
}

export default {
  register,
}
