/**
 * @param {array} files
 */
export const normalizeFiles = files => {
  const arr = files.reduce((acc, el) => {
    const data = `${el.name};${el.src}`

    acc.push(data)

    return acc
  }, [])

  return arr
}

/**
 * @param {string} file
 */
export const getFileName = file => {
  if (file) {
    const one = 1
    const lastIndexOf = file.lastIndexOf('/') + one

    return {
      src: file,
      name: file.substr(lastIndexOf),
    }
  }

  return null
}

export const generateBase64FromUrl = async files => {
  const preparedFiles = []

  await Promise.all(files.map(async url => {
    const res = await fetch(url)
    const blob = await res.blob()
    const fileName = getFileName(url)
    const fileData = new File([blob], fileName.name)

    preparedFiles.push({ fileData, fileName })
  }))

  return preparedFiles
}

export const alphabetSorting = (items, key) => items.sort((a, b) => a[key].localeCompare(b[key]))

export const clearPhone = phone => phone.replace(/[^+0-9]/g, '')

export const prettifyPhone = phone => {
  const digits = phone.replace(/\D/g, '')
  const prittyfied = digits.replace(/(\w{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')

  return prittyfied
}
