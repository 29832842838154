import { defineComponent as _defineComponent } from 'vue'
import { materialsDetailsCatalogResource } from '@/services/materials.service'
import { prefixLanguage } from '@/utils/urls'


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogDetails',
  props: {
  initial: {
    type: Object,
  },
  label: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const urlPath = prefixLanguage(`materials/category/${props.label}`)

const __returned__ = { props, urlPath, get materialsDetailsCatalogResource() { return materialsDetailsCatalogResource }, get prefixLanguage() { return prefixLanguage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})