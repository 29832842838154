import { App } from 'vue'
import View from './View.vue'
import ViewBase from './ViewBase.vue'

const register = (app: App<Element>): void => {
  app
    .component('list-view', View)
    .component('list-base-view', ViewBase)
}

export default {
  register,
}
