import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'

interface Props {
  rating?: number,
  readonly?: boolean,
  size?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Rating',
  props: {
    rating: {},
    readonly: { type: Boolean },
    size: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const props = __props

const highlightedIndex = ref(null)
const selectedStar = ref(null)

const setHighlightedItem = (index: number | any) => {
  highlightedIndex.value = index
}

const selectStar = (index: number) => {
  selectedStar.value = index

  emit('update:modelValue', selectedStar.value)
}

const __returned__ = { emit, props, highlightedIndex, selectedStar, setHighlightedItem, selectStar, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})