import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'order'

const ORDER_CREATE = prefixAPI('/create/', MODEL_PREFIX)

export const orderCreateResource = createResource(ORDER_CREATE, postResource)
