import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, defineAsyncComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Find',
  props: {
  externalSearchUrl: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const CertificateFindDate = defineAsyncComponent(() => import('@/components/Certificate/Step/Date'))
const CertificateFindType = defineAsyncComponent(() => import('@/components/Certificate/Step/Type'))
const CertificateFindNumber = defineAsyncComponent(() => import('@/components/Certificate/Step/Number'))
const CertificateFindEmail = defineAsyncComponent(() => import('@/components/Certificate/Step/Email'))
const CertificateFindDetails = defineAsyncComponent(() => import('@/components/Certificate/Step/Details'))

const props = __props

const currentStep = ref(1)

const formdata = reactive({
  date: 'old',
  type: 'serial_number',
})
const certificates = ref([])

const openExternalSearch = () => {
  window.open(props.externalSearchUrl, '_blank')
}

const goToNextStep = () => {
  if (formdata.date === 'old') {
    openExternalSearch()
  } else {
    currentStep.value += 1
  }
}

const goToPrevStep = () => {
  currentStep.value -= 1
}

const searchSuccess = (items: []) => {
  certificates.value = items

  goToNextStep()
}

const searchCancel = () => {
  currentStep.value = 1
  formdata.date = 'old'
  formdata.type = 'serial_number'

  certificates.value = []
}

const __returned__ = { CertificateFindDate, CertificateFindType, CertificateFindNumber, CertificateFindEmail, CertificateFindDetails, props, currentStep, formdata, certificates, openExternalSearch, goToNextStep, goToPrevStep, searchSuccess, searchCancel, ref, reactive, defineAsyncComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})