import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_datepicker = _resolveComponent("control-datepicker")!

  return (_openBlock(), _createBlock(_component_control_datepicker, {
    modelValue: $setup.model,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      $setup.debounceUpdate
    ],
    name: $setup.props.filter.name,
    "input-label": $setup.props.filter.label,
    placeholder: $setup.props.filter.placeholder || $setup.props.filter.title,
    config: $setup.dateConfig
  }, null, 8, ["modelValue", "name", "input-label", "placeholder", "onUpdate:modelValue"]))
}