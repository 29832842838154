import { App } from 'vue'

import Controls from './Controls'
import Modals from './Modals'
import Ui from './Ui'
import Sliders from './Sliders'
import Events from './Events'
import Reviews from './Reviews'
import List from './List'
import Profile from './Profile'
import Authentication from './Authentication'
import Forms from './Forms'
import Payment from './Payment'
import Exams from './Exams'
import Materials from './Materials'
import Category from './Category'
import Packs from './Packs'
import SummerCamp from './SummerCamp'
import Certificate from './Certificate'
import Cart from './Cart'
import Checkout from './Checkout'

const install = (app: App<Element>): void => {
  Controls.register(app)
  Modals.register(app)
  Ui.register(app)
  Sliders.register(app)
  Events.register(app)
  Reviews.register(app)
  List.register(app)
  Profile.register(app)
  Authentication.register(app)
  Forms.register(app)
  Payment.register(app)
  Exams.register(app)
  Materials.register(app)
  Category.register(app)
  Packs.register(app)
  SummerCamp.register(app)
  Certificate.register(app)
  Cart.register(app)
  Checkout.register(app)
}

export default {
  install,
}
