import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "i-count i-count--variant_1 i-count--size_1" }
const _hoisted_3 = { class: "i-count__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_cart = _resolveComponent("icon-cart")!
  const _component_modal_trigger = _resolveComponent("modal-trigger")!

  return (_openBlock(), _createBlock(_component_modal_trigger, {
    component: "Cart/View",
    classes: ['modal--size_xl']
  }, {
    default: _withCtx(({ open }) => [
      _createElementVNode("div", {
        class: "i-circle i-circle--variant_interactive",
        onClick: _withModifiers(open, ["prevent"])
      }, [
        _createVNode(_component_icon_cart),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($setup.cartStore.cartInfo.quantity || 0), 1)
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}