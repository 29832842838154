export const scrollToEventForm = {
  mounted: (node: HTMLElement): any => {
    const scrollNeeded = window.location.hash === '#form'

    if (!scrollNeeded) return

    setTimeout(() => {
      node.scrollIntoView()
    })
  }
}