import { reactive } from 'vue'

export default function () {
  const range = reactive({
    min: 0,
    max: Infinity,
  })
  const gaps = reactive({
    left: false,
    right: false,
  })
  const fills = reactive({
    left: [],
    right: [],
  })
  const one =  1
  const zero = 0
  const pageSizes = [
    10,
    20,
    40,
    60,
    80,
    100,
  ]

  const recalcRange = (page, extra) => {
    const [min, max] = page.getPageRange()
    const current = page.getPageNumber()
    const [start, end] = page.getPageRange(extra, current)
    // Some javascript magic going on here:
    // Filling up array with page numbers.
    const rangeArr = Array.from({ length:  end - start + one }, (x, i) => i + start)
    const currentIndex = rangeArr.indexOf(current)
    if (currentIndex !== -one) {
      fills.left = rangeArr.slice(zero, currentIndex)
      const minIndex = fills.left.indexOf(min)
      if (minIndex >= zero) {
        fills.left.splice(minIndex, one)
      }
      fills.right = rangeArr.slice(currentIndex + one)
      const maxIndex = fills.right.indexOf(max)
      if (maxIndex >= zero) {
        fills.right.splice(maxIndex, one)
      }
    } else {
      fills.left = []
      fills.right = []
    }
  
    gaps.left = (
      fills.left.length &&
      fills.left[0] !== min + one
    )
    gaps.right = (
      fills.right.length &&
      fills.right[fills.right.length - one] !== max - one
    )
    range.min = min
    range.max = max
  }

  return {
    range,
    gaps,
    fills,
    recalcRange,
    one,
    zero,
    pageSizes,
  }
}
