import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--justify_center" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_3 = { class: "ds-panel ds-panel--space_lg ds-panel--space_xl-xl" }
const _hoisted_4 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_5 = { class: "g-row g-row--justify_center" }
const _hoisted_6 = { class: "g-cell" }
const _hoisted_7 = { class: "d-control-checkbox d-control-checkbox--variant_flex" }
const _hoisted_8 = { class: "d-control-checkbox__content" }
const _hoisted_9 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_10 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_11 = { class: "error-label" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_13 = { class: "error-label" }
const _hoisted_14 = { class: "ds-panel ds-panel--space_lg ds-panel--space_xl-xl" }
const _hoisted_15 = { class: "ds-panel__element ds-panel__element--offset_top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.send }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12-xs g-cols--6-sm", $props.grid])
        }, [
          _createVNode(_component_control_input, {
            name: "name",
            type: "text",
            rules: "required|max:45",
            errors: errors,
            "input-label": $setup.$t("Ваше ім’я"),
            placeholder: $setup.$t("Введіть ім’я"),
            modelValue: $setup.formdata.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.name) = $event))
          }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12-xs g-cols--6-sm", $props.grid])
        }, [
          _createVNode(_component_control_input, {
            name: "email",
            errors: errors,
            "input-label": $setup.$t("Ваш E-mail"),
            placeholder: $setup.$t("Введіть E-mail"),
            type: "email",
            rules: "required|email",
            modelValue: $setup.formdata.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.email) = $event))
          }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12-xs g-cols--6-sm", $props.grid])
        }, [
          _withDirectives(_createVNode(_component_control_input, {
            name: "phone",
            type: "tel",
            rules: "required|maskedPhone:12",
            modelValue: $setup.formdata.phone,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formdata.phone) = $event)),
            errors: errors,
            "input-label": $setup.$t("Телефон"),
            placeholder: $setup.$t("Введіть Телефон")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"]), [
            [_directive_maska, '+38 (###) ###-##-##']
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12-xs g-cols--6-sm", $props.grid])
        }, [
          _createVNode(_component_control_input, {
            name: "city",
            type: "text",
            rules: "required|max:150",
            modelValue: $setup.formdata.city,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formdata.city) = $event)),
            errors: errors,
            "input-label": $setup.$t("Місто"),
            placeholder: $setup.$t("Введіть місто")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"])
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_VeeField, {
                    type: "checkbox",
                    name: "acceptPolicy",
                    rules: "required",
                    "unchecked-value": false,
                    modelValue: $setup.formdata.acceptPolicy,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formdata.acceptPolicy) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_7, [
                        _withDirectives(_createElementVNode("input", {
                          class: "d-control-checkbox__element",
                          name: "acceptPolicy",
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formdata.acceptPolicy) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, $setup.formdata.acceptPolicy]
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "d-control-checkbox__label" }, null, -1)),
                        _createElementVNode("span", _hoisted_8, [
                          _createVNode($setup["PrivacyPolicy"], {
                            text: $setup.$t("Ознайомлений/на та погоджуюся із")
                          }, null, 8, ["text"])
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_VeeError, { name: "acceptPolicy" }, {
                    default: _withCtx(({ message }) => [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(message), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(message), 1)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12-xs g-cols--6-md", $props.grid])
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_control_button, {
                disabled: $setup.isLoad,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.$t("Залишити заявку")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ], 2)
      ])
    ]),
    _: 1
  }))
}