import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["name", "value"]
const _hoisted_4 = { class: "d-control-checkbox__content" }
const _hoisted_5 = { class: "ds-caption ds-caption--size_sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filter.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "g-cell g-cols g-cols--12-xs g-cols--narrow-sm",
        key: item.label
      }, [
        _createElementVNode("label", {
          class: "d-control-checkbox d-control-checkbox--variant_flex",
          onClick: ($event: any) => ($setup.debounceClickHandler(item, $setup.model))
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "d-control-checkbox__element",
            type: "radio",
            name: $props.filter.name,
            value: item.label,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event))
          }, null, 8, _hoisted_3), [
            [_vModelRadio, $setup.model]
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "d-control-checkbox__label" }, null, -1)),
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(item.title), 1)
          ])
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}