import {
  createResource,
  postResource,
  baseResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const MODEL_PREFIX = 'review'

const REVIEW_DIRECTIONS = prefixAPI('/direction-choices/', MODEL_PREFIX)
const REVIEW_CREATE = prefixAPI('/create/', MODEL_PREFIX)
const REVIEW_PAYLOADS = prefixAPI('/payloads/{direction}/', MODEL_PREFIX)
const REVIEW_RETRIEVE = prefixAPI('/retrieve/{id}/', MODEL_PREFIX)

export const REVIEW_AVAILABLE_FILTERS = prefixAPI('/filters/', MODEL_PREFIX)

export const reviewDirectionsResource = createResource(REVIEW_DIRECTIONS)
export const reviewCreateResource = createResource(REVIEW_CREATE, postResource)
export const reviewPayloadsResource = createResource(REVIEW_PAYLOADS)
export const reviewRetrieveResource = createResource(REVIEW_RETRIEVE)
export const reviewFiltersAvailableResource = createResource(REVIEW_AVAILABLE_FILTERS)

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/ajax-reviews/')
const FILTERS_LIST_URL_POSTFIX = ''

export const reviewCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX
], baseResource)
