import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card card--space_25 relative" }
const _hoisted_2 = { class: "ds-panel ds-panel--space_xl ds-panel--space_2xl-xl" }
const _hoisted_3 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_4 = { class: "exams-content exams-content--size_23" }
const _hoisted_5 = { class: "g-row g-row--space_xl-sm g-row--justify_center" }
const _hoisted_6 = {
  key: 0,
  class: "g-cell g-cols g-cols--6-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_button = _resolveComponent("control-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode($setup["CertificateFindDate"], {
      date: $setup.formdata.date,
      "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.date) = $event))
    }, null, 8, ["date"]), [
      [_vShow, $setup.currentStep === 1]
    ]),
    _withDirectives(_createVNode($setup["CertificateFindType"], {
      type: $setup.formdata.type,
      "onUpdate:type": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.type) = $event))
    }, null, 8, ["type"]), [
      [_vShow, $setup.currentStep === 2]
    ]),
    _withDirectives(_createVNode($setup["CertificateFindNumber"], {
      serial_number: $setup.formdata.serial_number,
      "onUpdate:serial_number": _cache[2] || (_cache[2] = ($event: any) => (($setup.formdata.serial_number) = $event)),
      "onSearch:success": $setup.searchSuccess
    }, null, 8, ["serial_number"]), [
      [_vShow, $setup.currentStep === 3 && $setup.formdata.type === 'serial_number']
    ]),
    _withDirectives(_createVNode($setup["CertificateFindEmail"], { "onSearch:success": $setup.searchSuccess }, null, 512), [
      [_vShow, $setup.currentStep === 3 && $setup.formdata.type === 'email']
    ]),
    ($setup.currentStep === 4 && $setup.certificates.length)
      ? (_openBlock(), _createBlock($setup["CertificateFindDetails"], {
          key: 0,
          certificates: $setup.certificates,
          "onSearch:cancel": $setup.searchCancel
        }, null, 8, ["certificates"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            ([2, 3].includes($setup.currentStep))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_control_button, {
                    onClick: _withModifiers($setup.goToPrevStep, ["prevent"]),
                    variant: ['transparent'],
                    size: ['xl']
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Назад")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            ([1, 2].includes($setup.currentStep))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["g-cell g-cols", $setup.currentStep === 1 ? 'g-cols--8-xs g-cols--6-sm' : 'g-cols--6-xs'])
                }, [
                  _createVNode(_component_control_button, {
                    onClick: _withModifiers($setup.goToNextStep, ["prevent"]),
                    variant: ['transparent'],
                    size: ['xl']
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Далі")), 1)
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}