import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { $vfm } from 'vue-final-modal'
import { getVideoId, getVideoPreview } from '@utils/video'

interface Props {
  url: string,
  previewBase?: string,
  previewWebp?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoCircle',
  props: {
    url: {},
    previewBase: {},
    previewWebp: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
const youtubePreview = ref('')

const setVideoPreview = () => {
  const videoId = getVideoId(props.url)

  youtubePreview.value = getVideoPreview(videoId)
}

if (!props.previewBase) {
  setVideoPreview()
}

const openVideoModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: 'modal--size_xl',
    component: 'Modals/Common/Video',
    info: {
      url: props.url,
    },
  })
}

const __returned__ = { props, youtubePreview, setVideoPreview, openVideoModal, ref, get $vfm() { return $vfm }, get getVideoId() { return getVideoId }, get getVideoPreview() { return getVideoPreview } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})