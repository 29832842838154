import { App } from 'vue'
import Controller from './ControlDescriptor.vue'
import InputLabel from './Label.vue'
import SimpleTextarea from './SimpleTextarea.vue'
import SimpleInput from './SimpleInput.vue'
import Multiselect from './Multiselect.vue'
import ControlButton from './Button.vue'
import Uploader from './Uploader.vue'
import Datepicker from './Datepicker.vue'
import Autocomplete from './Autocomplete.vue'

const register = (app: App<Element>): void => {
  app
    .component('ControlInput', Controller)
    .component('InputLabel', InputLabel)
    .component('SimpleTextarea', SimpleTextarea)
    .component('SimpleInput', SimpleInput)
    .component('ControlSelect', Multiselect)
    .component('ControlButton', ControlButton)
    .component('ControlUploader', Uploader)
    .component('ControlDatepicker', Datepicker)
    .component('ControlAutocomplete', Autocomplete)
}

export default {
  register,
}
