import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Percentage',
  props: {
  total: {
    type: Number,
  },
  current: {
    type: Number,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const percent = computed(() => {
  const result = (props.current / props.total) * 100
  const preparedPercent = result > 100 ? 100 : (result < 0 ? 0 : result)

  return preparedPercent
})

const __returned__ = { props, percent, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})