import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_base_view = _resolveComponent("list-base-view")!

  return (_openBlock(), _createBlock(_component_list_base_view, {
    "url-path": $setup.urlPath,
    initial: $props.initial,
    "list-resource": $setup.eventCatalogResource,
    "prepended-filters": $setup.prependedFilters,
    "appended-filters": $setup.appendedFilters,
    "filter-grid": "g-cols--4-lg g-cols--3-hd g-cols--2-half-mac",
    "is-common-reset-button": false
  }, {
    heading: _withCtx(() => [
      _renderSlot(_ctx.$slots, "heading")
    ]),
    additional: _withCtx(() => [
      _renderSlot(_ctx.$slots, "additional")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["url-path", "initial", "list-resource", "prepended-filters", "appended-filters"]))
}