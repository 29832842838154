import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, defineAsyncComponent, nextTick } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { paymentsCreateResource } from '@/services/payments.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'Form',
  props: {
  orderId: {
    type: String,
  },
  items: {
    type: Array,
  },
  userEmail: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const IndividualEntityForm = defineAsyncComponent(() => import('@/components/Payment/IndividualEntity'))
const LegalEntityForm = defineAsyncComponent(() => import('@/components/Payment/LegalEntity'))

const props = __props

const isLoad = ref(false)

const formdata = reactive({
  eventRequest: props.orderId,
  gateway: { service: null },
})

const gatewayChangeHandler = async () => {
  formdata.email = props.userEmail
}

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  preparedFormdata.gateway = preparedFormdata.gateway.value

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  paymentsCreateResource.execute({}, preparedFormdata).then(res => {
    const { data: { redirect } } = res

    window.location = redirect.location
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

const __returned__ = { IndividualEntityForm, LegalEntityForm, props, isLoad, formdata, gatewayChangeHandler, prepareFormdata, send, ref, reactive, defineAsyncComponent, nextTick, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get paymentsCreateResource() { return paymentsCreateResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})