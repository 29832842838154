import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'locations'

const LOCATIONS_AREA_LIST = prefixAPI('/areas/', MODEL_PREFIX)
const LOCATIONS_SETTLEMENT_LIST = prefixAPI('/search-settlement/', MODEL_PREFIX)

export const locationsAreaListResource = createResource(LOCATIONS_AREA_LIST)
export const locationsSettlementListResource = createResource(LOCATIONS_SETTLEMENT_LIST, postResource)
