import useRoute from '@/router/utils'
import { LIST_URL, LIST_FAVOURITE_URL } from './consts'
import { optionsT } from '@/router'

const List = () => import('./views/List.vue')
const ListFavourite = () => import('./views/ListFavourite.vue')

export const LIST = 'profile:event:list'
export const LIST_FAVOURITE = 'profile:event:favourite'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, {}),
    useRoute(LIST_FAVOURITE_URL, ListFavourite, LIST_FAVOURITE, {}),
  ]
}
