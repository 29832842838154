<template>
<svg version="1.1" viewBox="-40 -40 1148 1148" width="267" height="267" xmlns="http://www.w3.org/2000/svg">
<path transform="translate(187,16)" d="m0 0h586l8 7 9 9 7 8 15 15 7 8 12 12 7 8 14 14 7 8 14 14 7 8 14 14 7 8 11 11 7 8 13 13 2 7-1 804-2 17-8 16-7 9-11 8-11 5-7 2-9 1-63 1h-577l-54-1-13-3-13-7-10-9-8-11-5-13-1-5v-939l4-12 6-11 7-8 11-8 13-6zm-3 25-10 4-7 6-6 9-3 9v928l3 10 6 8 7 6 12 5 15 1h18l37-1h624l9-3 8-6 5-5 6-12 1-9v-791l-3-1h-139l-6-1-4-8-1-149zm597 19v116h110l-2-4-14-14-1-2h-2l-2-4-17-17-7-8-17-17-7-8-11-11-7-8-13-13-7-8z" fill="#B84333"/>
<path transform="translate(528,448)" d="m0 0h8l7 2 1 4v41h30l5 2 4 5v8l-4 6-4 1h-31v32h31l6 3 2 3v9l-3 5-6 2-30 1v30h28l7 2 4 5v8l-4 6-1 1-7 1h-27v31h32l5 3 2 4v8l-4 6-4 1h-31v32h33l4 2 2 4v8l-3 5-2 2-19 1h-15v54h42l5 3 2 6 6 77 3 38-1 13-3 12-4 9-6 10-6 7-10 8-16 8-13 3h-21l-12-3-16-8-10-9-7-8-6-10-5-14-1-5v-23l8-105 4-8 2-1h43v-27h-30l-5-2-4-5v-8l4-6 4-2 30-1v-18l1-12h-32l-6-5-2-5 1-5 6-7 32-1v-18l1-13h-33l-5-5-2-7 3-6 6-4h31v-31h-31l-5-3-4-6 1-6 3-5 3-2 32-1v-19l1-12h-30l-6-2-3-5-1-6 4-7 4-2h32l-1-19 1-12h-30l-6-3-3-4-1-5 4-8 3-2 32-1 1-13 2-5zm37 359-70 1-1 1-6 79-1 12v18l3 12 6 10 5 6 10 7 10 4 4 1h17l10-3 10-6 8-8 7-12 2-8v-22l-6-82-1-9z" fill="#B84535"/>
<path transform="translate(243,133)" d="m0 0h102l1 1v19l-8 11-10 12-9 11-13 16-14 17-8 10-11 14-6 7-1 2h82l1 1v20l-2 1h-112v-20l3-5 9-11 13-16 11-14 13-16 11-14 18-22 1-2h-71z" fill="#B84333"/>
<path transform="translate(416,133)" d="m0 0h65l16 2 10 4 10 9 5 8 3 11v16l-2 9-6 11-4 5-9 6-13 4-23 2h-29v54l-3 1h-19l-1-1zm23 22v43h42l10-3 6-5 3-6 1-4v-9l-3-7-5-5-4-3-3-1z" fill="#B84333"/>
<path transform="translate(511,866)" d="m0 0h42l5 3 2 2 2 15 1 13v25l-4 9-6 7-10 5-4 1h-11l-10-3-8-7-6-9-2-11 3-37 2-8 2-4zm18 21-2 1-2 18v11l3 5 6 2 5-2 2-4v-15l-1-14-4-2z" fill="#B84434"/>
<path transform="translate(366,133)" d="m0 0h22l1 1v140l-2 1h-19l-2-1z" fill="#B84333"/>
<path transform="translate(909,209)" d="m0 0h1l1 14v82l1 78v467l-1 105 1 44h-2l-1-9z" fill="#AF322B"/>
<path transform="translate(542,747)" d="m0 0h1v36h-2l-1-8 1-17z" fill="#AF322B"/>
<path transform="translate(156,923)" d="m0 0h1v34h-2l-1-20 1-6z" fill="#AF322B"/>
<path transform="translate(909,990)" d="m0 0h1l1 11-6 8-1-2 3-7 1-9z" fill="#B53F34"/>
</svg>

</template>