<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
    <line fill="#FFFFFF" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="8" y1="1"
      x2="8" y2="14.9" />
    <g>
      <g>

      <line fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="11.5"
        y1="12.8" x2="12" y2="12.8" />
      <polyline fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="14.2,12.8 
      14.7,12.8 14.7,12.3 		" />

      <line fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
        stroke-dasharray="0.8091,2.4273" x1="14.7" y1="9.9" x2="14.7" y2="4.6" />
      <polyline fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="14.7,3.4 
      14.7,2.9 14.2,2.9 		" />

      <line fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="12"
        y1="2.9" x2="11.5" y2="2.9" />
    </g>
  </g>
  <polyline fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10" points="4.5,2.9 
  1.3,2.9 1.3,12.8 4.5,12.8 " />
</svg></template>