import { App, defineAsyncComponent } from 'vue'

const iconsComp = require.context('@/icons/', true, /\.vue$/)

const register = (app: App<Element>): void => {
  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, '').replace(/\.\w+$/, '')

    app.component(`Icon${name}`, defineAsyncComponent(() => import(`@/icons/${name}.vue`)))
  }
}

export default {
  register,
}
