<template>
  <render ref="content" />
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { generateBase64FromUrl } from '@utils/transformers'

const slots = useSlots() as any

interface Props {
  files: string[],
  title: string,
}

interface File {
  fileData: string,
  fileName: {
    src: string,
    name: string,
  },
}

const props = defineProps<Props>()

const download = async () => {
  const files: File[] = await generateBase64FromUrl(props.files)

  const zip = new JSZip()

  files.forEach((el: File) => {
    zip.file(el.fileName.name, el.fileData, { base64: true })
  })

  const title = props.title.replace(/ /g, '-')

  zip.generateAsync({ type: 'blob' }).then((content: string) => {
    saveAs(content, `${title}.zip`)
  })
}

const render = () => slots.default({
  download,
})
</script>