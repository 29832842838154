import { App } from 'vue'
import RequestFormEvent from './RequestFormEvent.vue'
import RequestFormCourse from './RequestFormCourse.vue'
import RequestFormWrapper from './RequestFormWrapper.vue'
import Catalog from './Catalog.vue'

const register = (app: App<Element>): void => {
  app
    .component('event-request-form-event', RequestFormEvent)
    .component('event-request-form-course', RequestFormCourse)
    .component('event-request-form-wrapper', RequestFormWrapper)
    .component('event-catalog', Catalog)
}

export default {
  register,
}
