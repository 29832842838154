import { defineComponent as _defineComponent } from 'vue'
import { Navigation } from 'swiper'


export default /*@__PURE__*/_defineComponent({
  __name: 'Reviews',
  setup(__props, { expose: __expose }) {
  __expose();

const modules = [Navigation]

const breakpoints = {
  1200: {
    spaceBetween: 30,
  },
}

const __returned__ = { modules, breakpoints, get Navigation() { return Navigation } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})