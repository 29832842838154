import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-heading"
}
const _hoisted_2 = { class: "ds-caption ds-caption--size_xl ds-caption--size_2xl-md ds-caption--size_3xl-xl ds-caption--size_4xl-hd ds-caption--appearance_center ds-caption--ff_bahnschrift ds-caption--weight_semi-bold" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_7 = { class: "ds-panel ds-panel--space_lg ds-panel--space_3xl-xl" }
const _hoisted_8 = { class: "ds-panel__element ds-panel__element--offset_left" }
const _hoisted_9 = { class: "ds-caption ds-caption--size_xs ds-caption--size_sm-sm ds-caption--color_dark" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_12 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_14 = { class: "error-label" }
const _hoisted_15 = { class: "ds-panel ds-panel--space_lg" }
const _hoisted_16 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_17 = { class: "error-label" }
const _hoisted_18 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl" }
const _hoisted_19 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_20 = { class: "ds-panel ds-panel--space_sm" }
const _hoisted_21 = { class: "ds-panel__element ds-panel__element--offset_vertical" }
const _hoisted_22 = { class: "ds-caption ds-caption--color_dark ds-caption--size_xs ds-caption--size_sm-sm ds-caption--weight_semi-bold ds-caption--appearance_center" }
const _hoisted_23 = { class: "ds-panel ds-panel--space_lg" }
const _hoisted_24 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_25 = { class: "ds-caption ds-caption--size_xs ds-caption--size_sm-sm ds-caption--size_2sm-xl ds-caption--size_3md-hd ds-caption--color_dark ds-caption--appearance_center" }
const _hoisted_26 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_modal_trigger = _resolveComponent("modal-trigger")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.prepareFormdata }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      (!$props.hideHeading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Вхід")), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_control_input, {
            name: "username",
            errors: errors,
            "input-label": _ctx.$t("Е-mail"),
            type: "email",
            rules: "required|email",
            modelValue: $setup.formdata.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.username) = $event))
          }, null, 8, ["errors", "input-label", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_control_input, {
            name: "password",
            errors: errors,
            "input-label": _ctx.$t("Пароль"),
            type: "password",
            rules: "required|min:8|max:32|repeatSymbols:3",
            modelValue: $setup.formdata.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.password) = $event))
          }, null, 8, ["errors", "input-label", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, [
                _createTextVNode(_toDisplayString(_ctx.$t("Не пам’ятаєте пароль?")) + " ", 1),
                _createVNode(_component_modal_trigger, { component: "Modals/Authentication/PasswordForgot" }, {
                  default: _withCtx(({ open }) => [
                    _createElementVNode("span", {
                      class: "ds-link ds-link--size_xs ds-link--size_sm-sm ds-link--inline",
                      onClick: _withModifiers(($event: any) => {$setup.props.close();open()}, ["prevent"])
                    }, _toDisplayString(_ctx.$t("Відновити пароль")), 9, _hoisted_10)
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(message), 1)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_VeeError, { name: "acceptPolicy" }, {
        default: _withCtx(({ message }) => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(message), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_control_button, {
            disabled: $setup.isLoad,
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Увійти")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("або")), 1)
        ])
      ]),
      _createVNode($setup["SocialNetworks"]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("p", _hoisted_25, [
            _createTextVNode(_toDisplayString(_ctx.$t("Я не маю акаунту.")) + " ", 1),
            _createVNode(_component_modal_trigger, {
              component: "Authentication/Registration",
              classes: ['modal--size_xl']
            }, {
              default: _withCtx(({ open }) => [
                _createElementVNode("span", {
                  class: "ds-link ds-link--size_xs ds-link--size_sm-sm ds-link--size_2sm-xl ds-link--size_3md-hd ds-link--inline",
                  onClick: _withModifiers(($event: any) => {$setup.props.close();open()}, ["prevent"])
                }, _toDisplayString(_ctx.$t("Зареєструватися")), 9, _hoisted_26)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}