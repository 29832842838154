import { defineStore } from 'pinia'
import { cartRetrieveResource } from '@/services/cart.service'

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: {
      groups: [],
      quantity: 0,
      totalPrice: 0,
      totalClearPrice: 0,
      totalPromocodeDiff: 0,
      promocode: {},
      isDeterminated: false,
    },
  }),

  getters: {
    cartInfo: state => state.cart,
  },

  actions: {
    async getCartData() {
      const { data: { item } } = await cartRetrieveResource.execute()

      this.setCartInfo(item)
    },

    setCartInfo(data: any) {
      this.cart = { ...data, isDeterminated: true }
    },
  },
})
