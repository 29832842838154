import createRouter from '@/router'
import Components from '@/components'
import Modules from '@/modules'
import Directives from '@/directives'
import Icons from '@/icons'
import { useUserStore } from '@/store/user'
import { useCartStore } from '@/store/cart'

type createApplication = {
  createApp: any,
  createRouter: any,
  createWebHistory: any,
  createPinia: any,
}

export function createApplication(
  {
    createApp,
    createRouter: createInstance,
    createWebHistory,
    createPinia,
  }: createApplication
): any {
  const pinia = createPinia()

  const app = createApp({
    delimiters: ['[[', ']]'],

    async created() {
      const cartStore = useCartStore()

      cartStore.getCartData()

      if (window.isAuthenticated) {
        const userStore = useUserStore()

        userStore.getUserData()
      }
    },
  })

  const router = createRouter({ app, createInstance, createWebHistory, pinia })

  app.use(pinia)
  app.use(Modules)
  app.use(router)

  Components.install(app)
  Directives.register(app)
  Icons.register(app)

  app.mount('#app')

  return app
}
