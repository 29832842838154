import { App } from 'vue'
import Feedback from './Feedback.vue'

const register = (app: App<Element>): void => {
  app
    .component('form-feedback', Feedback)
}

export default {
  register,
}
