import { App } from 'vue'

import i18n from './i18n'
import Validation from './validation/plugin'
import VueFinalModal from './vue-final-modal'
import VueMask from './vue-inputmask'
import VueSwiper from './vue-swiper'
import VueRecaptchaV3 from './vue-recaptcha-v3'
import VueObserveVisibility from './vue-observe-visibility'
import VueTooltip from './vue-tooltip'
import VueViewer from './vue-viewer'
// import VuePdf from './vue-pdf'

export default {
  install(app: App<Element>): any {
    app
      .use(i18n)
      .use(Validation)
      .use(VueFinalModal)
      .use(VueMask)
      .use(VueSwiper)
      .use(VueRecaptchaV3)
      .use(VueObserveVisibility)
      .use(VueTooltip)
      .use(VueViewer)
      // .use(VuePdf)
  }
}
