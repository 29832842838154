import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = '2factor'

const TWO_FACTOR_CONFIRM = prefixAPI('/confirm/', MODEL_PREFIX)
const TWO_FACTOR_REQUEST = prefixAPI('/request-confirmation/', MODEL_PREFIX)

export const twoFactorConfirmResource = createResource(TWO_FACTOR_CONFIRM, postResource)
export const twoFactorRequestResource = createResource(TWO_FACTOR_REQUEST, postResource)
