import {
  baseResource,
} from '@/resource/resource'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const CATALOG_SUMMER_CAMP_LIST_URL_PREFIX = prefixLanguage('/ajax-summer-camp/')

export const summerCampCatalogResource = friendlyUrlGenerator([
  CATALOG_SUMMER_CAMP_LIST_URL_PREFIX, ''
], baseResource)
