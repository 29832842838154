import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { simpleTransformer } from '@resource/transformer'

const MODEL_PREFIX = 'authenticate'

const LOGIN = prefixAPI('/login/', MODEL_PREFIX)
const REGISTER = prefixAPI('/register/', MODEL_PREFIX)
const LOGOUT = prefixAPI('/logout/', MODEL_PREFIX)
const EMAIL_CHANGE = prefixAPI('/change-email/', MODEL_PREFIX)
const EMAIL_CHECK = prefixAPI('/check-email/', MODEL_PREFIX)
const PASSWORD_CHECK = prefixAPI('/password/check/', MODEL_PREFIX)
const PASSWORD_CHANGE = prefixAPI('/password/change/', MODEL_PREFIX)
const PASSWORD_RESET = prefixAPI('/password/reset/', MODEL_PREFIX)
const PASSWORD_RESET_CONFIRM = prefixAPI('/password/reset/confirm/', MODEL_PREFIX)
const INSTITUTION_TYPE_LIST = prefixAPI('/institution-type-choices/', MODEL_PREFIX)

export const loginResource = createResource(LOGIN, postResource)
export const registerResource = createResource(REGISTER, postResource)
export const logoutResource = createResource(LOGOUT, postResource, simpleTransformer)
export const emailChangeResource = createResource(EMAIL_CHANGE, postResource)
export const emailCheckResource = createResource(EMAIL_CHECK, postResource)
export const passwordCheckResource = createResource(PASSWORD_CHECK, postResource)
export const passwordChangeResource = createResource(PASSWORD_CHANGE, postResource)
export const passwordResetResource = createResource(PASSWORD_RESET, postResource)
export const passwordResetConfirmResource = createResource(PASSWORD_RESET_CONFIRM, postResource)
export const institutionTypeListResource = createResource(INSTITUTION_TYPE_LIST)
