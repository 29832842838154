import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "video-circle" }
const _hoisted_2 = { class: "video-circle__badge" }
const _hoisted_3 = { class: "video-circle__badge-text" }
const _hoisted_4 = { class: "ds-caption ds-caption--size_sm ds-caption--size_md-xl" }
const _hoisted_5 = { class: "video-circle__play" }
const _hoisted_6 = {
  key: 0,
  class: "video-circle__picture"
}
const _hoisted_7 = ["srcset"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "video-circle__picture"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_play = _resolveComponent("icon-play")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "video-circle__image-wrapper",
      onClick: _withModifiers($setup.openVideoModal, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Переглянути відео")), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "video-circle__badge-arrow",
          src: '/static/app/images/arrow-line-small.svg'
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_icon_play)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "video-circle__circles-wrapper" }, [
        _createElementVNode("img", {
          class: "video-circle__circles",
          src: '/static/app/images/video-circles.svg'
        })
      ], -1)),
      ($props.previewBase)
        ? (_openBlock(), _createElementBlock("picture", _hoisted_6, [
            _createElementVNode("source", {
              type: "image/webp",
              srcset: $props.previewWebp
            }, null, 8, _hoisted_7),
            _createElementVNode("img", {
              class: "video-circle__image",
              src: $props.previewBase
            }, null, 8, _hoisted_8)
          ]))
        : (_openBlock(), _createElementBlock("picture", _hoisted_9, [
            _createElementVNode("img", {
              class: "video-circle__image",
              src: $setup.youtubePreview
            }, null, 8, _hoisted_10)
          ]))
    ])
  ]))
}