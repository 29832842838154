<template>
  <svg width="41" height="28" viewBox="0 0 41 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3242 3.33038C25.632 3.24756 26.8658 3.18526 28.0968 3.08851C31.2438 2.84119 34.335 2.2784 37.3598 1.37361C37.7196 1.26595 37.9225 1.33013 38.1516 1.61605C40.0673 4.00666 40.5827 6.73758 39.9985 9.67235C39.7662 10.8393 39.2533 11.9504 38.868 13.0867C38.778 13.3517 38.6212 13.3963 38.3321 13.3625C35.7514 13.0587 33.1673 12.7825 30.5847 12.4961C30.48 12.4846 30.3255 12.4878 30.2822 12.4242C29.8115 11.733 28.9923 11.2297 29.1026 10.2184C29.1292 9.97557 29.0735 9.71696 29.02 9.47358C28.754 8.26294 28.0145 7.6147 26.7169 7.50609C26.2859 7.46995 26.1084 7.32691 25.9493 6.89475C25.5569 5.82903 25.0487 4.80566 24.5819 3.76743C24.5196 3.62927 24.4279 3.50429 24.3242 3.33038Z" fill="#F2C15F" style="fill:#F2C15F;fill:color(display-p3 0.9490 0.7569 0.3725);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66758 13.3788C8.60138 9.31375 8.6825 5.3095 6.30469 1.33142C7.27234 1.52303 8.21552 1.74175 9.16943 1.89232C11.4123 2.24618 13.6587 2.57915 15.9076 2.89254C16.3799 2.95842 16.7554 3.10598 17.0872 3.45965C18.0731 4.51012 18.7812 5.70665 19.1262 7.11042C19.1411 7.17103 19.1533 7.23276 19.1593 7.29469C19.162 7.32236 19.1464 7.35172 19.1332 7.40273C18.7566 7.45694 18.3671 7.48875 17.9888 7.57175C16.8994 7.81098 16.4324 8.30488 16.2628 9.40109C16.2005 9.80238 16.2283 10.2193 16.1472 10.6153C16.081 10.9387 15.9592 11.2713 15.78 11.5461C15.5423 11.9105 15.2317 12.2289 14.9373 12.5536C14.8524 12.6472 14.7201 12.7447 14.6021 12.7548C11.9785 12.9796 9.3537 13.1925 6.72894 13.4057C6.7041 13.4078 6.67718 13.3833 6.66758 13.3788Z" fill="#F2C15F" style="fill:#F2C15F;fill:color(display-p3 0.9490 0.7569 0.3725);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5888 14.024C25.5907 12.349 24.2598 10.9991 22.5942 10.9863C20.907 10.9735 19.542 12.3264 19.5401 14.0134C19.5382 15.7042 20.9007 17.0625 22.5842 17.0483C24.2603 17.0344 25.5871 15.699 25.5888 14.024ZM25.9165 18.8803C25.7686 18.8558 25.4698 18.8287 25.1836 18.7542C24.7356 18.6373 24.4369 18.7994 24.1852 19.1657C23.974 19.4734 23.7474 19.784 23.4726 20.0321C22.8665 20.579 22.2287 20.5653 21.6262 20.004C21.3769 19.7721 21.159 19.4951 20.974 19.2082C20.711 18.8003 20.3986 18.668 19.9043 18.7531C19.4215 18.8363 18.9112 18.8289 18.4215 18.7796C18.0326 18.7405 17.7875 18.461 17.7522 18.0439C17.7234 17.7009 17.6744 17.3583 17.6682 17.0152C17.655 16.2936 17.4207 15.6668 16.9285 15.1375C16.8752 15.0801 16.8235 15.0214 16.77 14.9638C15.7593 13.8738 15.75 13.7755 16.796 12.6852C17.3815 12.0749 17.6676 11.3749 17.6718 10.5285C17.6793 9.11665 17.8222 8.95779 19.2175 8.94292C20.1196 8.93351 20.8473 8.62388 21.4906 7.99992C22.4848 7.03585 22.6584 7.03886 23.6648 8.01931C24.2957 8.63329 25.0145 8.92956 25.8981 8.94141C27.3792 8.96155 27.5424 9.14582 27.6241 10.6352C27.6655 11.3851 27.9549 12.0288 28.452 12.5846C28.5878 12.7365 28.7376 12.8775 28.8592 13.0398C29.248 13.5593 29.3017 13.9299 28.8456 14.3829C27.8817 15.3404 27.3671 16.4184 27.3993 17.8106C27.4178 18.6059 26.9493 18.8875 25.9165 18.8803Z" fill="#C6513D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4284 14.2745C14.7941 14.8346 15.1248 15.3412 15.4905 15.9013C14.9304 15.9788 14.3755 16.0274 13.8327 16.1355C10.3022 16.8383 6.77187 17.5428 3.24834 18.2799C2.80564 18.3723 2.58147 18.2976 2.3413 17.9207C1.12651 16.015 0.472248 13.9229 0.294189 11.6898C0.0481826 8.60598 0.451167 5.61475 1.86924 2.82887C2.20408 2.17122 2.73054 1.60975 3.18002 1.01233C3.3848 0.740162 3.59674 0.787783 3.84971 0.997274C4.90639 1.87213 5.64987 2.96401 6.06378 4.25955C6.88217 6.8222 6.75587 9.35624 5.70841 11.8349C5.44528 12.4576 5.09274 13.0302 4.4477 13.5197C4.26042 13.1258 4.05281 12.7828 3.92914 12.4118C3.4791 11.0623 3.4919 9.66699 3.58564 8.26982C3.61387 7.84839 3.70384 7.43091 3.72511 7.00948C3.74111 6.68988 3.88548 6.60838 4.17214 6.63887C4.35829 6.65863 4.54915 6.65901 4.73587 6.64414C5.17932 6.60857 5.46523 6.29329 5.45318 5.87167C5.44189 5.47076 5.1637 5.17544 4.73304 5.15323C4.21656 5.12669 3.69857 5.12236 3.18134 5.12236C2.75972 5.12217 2.5043 5.35538 2.42581 5.75742C2.00193 7.92819 1.8346 10.1059 2.32624 12.2895C2.53216 13.2041 2.90766 14.0385 3.57114 14.7274C3.86477 15.0321 4.19021 15.1644 4.61973 15.1236C6.86617 14.9099 9.11392 14.7082 11.3619 14.5115C12.4061 14.42 13.4521 14.3494 14.4284 14.2745Z" fill="#F2C15F" style="fill:#F2C15F;fill:color(display-p3 0.9490 0.7569 0.3725);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0117 21.4592C22.0644 22.082 23.0763 22.0858 24.12 21.4558V27.8482C23.7835 27.5245 23.5023 27.2478 23.2145 26.9784C22.7432 26.5374 22.3846 26.541 21.9133 26.9869C21.6332 27.2519 21.3564 27.5209 21.0117 27.8518V21.4592Z" fill="#7FBC99" style="fill:#7FBC99;fill:color(display-p3 0.4980 0.7373 0.6000);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5352 16.0506C29.9496 15.3959 30.362 14.7449 30.8491 13.9756C33.0344 14.2124 35.3605 14.4644 37.8137 14.7304C37.0349 15.7906 36.2524 16.6378 35.2063 17.2098C35.1261 17.2539 34.9959 17.2292 34.8952 17.2076C33.1522 16.8364 31.4104 16.4607 29.6684 16.0856C29.611 16.0731 29.5549 16.0558 29.5352 16.0506Z" fill="#F2C15F" style="fill:#F2C15F;fill:color(display-p3 0.9490 0.7569 0.3725);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7074 6.66846C20.1371 5.50242 19.5719 4.34673 19.0586 3.29739C20.0291 3.29739 21.1296 3.29363 22.2301 3.30436C22.3216 3.3053 22.4494 3.38115 22.4978 3.46039C23.0576 4.3776 23.6059 5.30177 24.1534 6.22651C24.1715 6.25719 24.1494 6.31197 24.1395 6.43958C22.8449 5.29218 21.7022 5.71154 20.7074 6.66846Z" fill="#7FBC99" style="fill:#7FBC99;fill:color(display-p3 0.4980 0.7373 0.6000);fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0426 14.0394C21.0509 14.8824 21.7311 15.5519 22.573 15.5461C23.4166 15.5403 24.0829 14.8679 24.0845 14.0208C24.0858 13.1595 23.3892 12.4732 22.5309 12.4905C21.6927 12.5073 21.0341 13.1926 21.0426 14.0394ZM25.5876 14.024C25.5861 15.6991 24.2593 17.0346 22.5832 17.0485C20.8995 17.0624 19.5372 15.7042 19.5391 14.0134C19.5408 12.3264 20.9057 10.9734 22.593 10.9864C24.2587 10.999 25.5895 12.349 25.5876 14.024Z" fill="white" style="fill:white;fill:white;fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.043 14.0394C21.0346 13.1926 21.6932 12.5073 22.5313 12.4906C23.3896 12.4732 24.0862 13.1595 24.0849 14.0208C24.0834 14.868 23.4171 15.5403 22.5735 15.5462C21.7316 15.552 21.0513 14.8825 21.043 14.0394Z" fill="#7FBC99" style="fill:#7FBC99;fill:color(display-p3 0.4980 0.7373 0.6000);fill-opacity:1;"/>
  </svg>
</template>