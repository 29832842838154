import { App } from 'vue'
import Catalog from './Catalog.vue'

const register = (app: App<Element>): void => {
  app
    .component('summer-camp-catalog', Catalog)
}

export default {
  register,
}
