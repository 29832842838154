<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="bg" cx="20" cy="20" r="20" fill="#F6F6F6" />
    <mask id="mask0_0_5124" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
      <circle cx="20" cy="20" r="20" fill="white" />
    </mask>
    <g mask="url(#mask0_0_5124)">
      <circle opacity="0.100066" cx="20.1699" cy="44.6808" r="20" fill="#D64633" />
    </g>
    <circle opacity="0.100066" cx="19.9996" cy="15.7447" r="7.74468" fill="#D64633" />
  </svg>
</template>