import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { debounce } from 'lodash'
import moment from 'moment'
import { RANGE_PREFIX, RANGE_POSTFIX } from '@/utils/filters'


export default /*@__PURE__*/_defineComponent({
  __name: 'DatepickerWidget',
  props: {
  filter: Object,
  config: Object,
  resetTrigger: Boolean,
  valueFromUrl: Object,
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const dateConfig = {
  textInput: false,
  range: props.config.range,
  autoApply: false,
}

const emit = __emit

const model = ref(null)

const update = (value: any) => {
  const filterName = props.filter.name
  const obj: Record<string, string> = {
    [filterName]: '',
  }

  if (value && value[0]) {
    obj[`${filterName}${RANGE_PREFIX}`] = moment(value[0]).format('DD.MM.YYYY')
  } else {
    obj[`${filterName}${RANGE_PREFIX}`] = ''
  }

  if (value && value[1]) {
    obj[`${filterName}${RANGE_POSTFIX}`] = moment(value[1]).format('DD.MM.YYYY')
  } else {
    obj[`${filterName}${RANGE_POSTFIX}`] = ''
  }

  emit('update:modelValue', obj)
}

watch(() => props.resetTrigger, () => model.value = null)

const debounceUpdate = debounce(update, 100)

watch(() => props.valueFromUrl, () => {
  if (!props.valueFromUrl) return

  const preparedValue = props.valueFromUrl.map((el: string) => {
    return moment(el, 'DD.MM.YYYY').format()
  })

  model.value = preparedValue
}, { immediate: true })

const __returned__ = { props, dateConfig, emit, model, update, debounceUpdate, ref, watch, get debounce() { return debounce }, get moment() { return moment }, get RANGE_PREFIX() { return RANGE_PREFIX }, get RANGE_POSTFIX() { return RANGE_POSTFIX } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})