import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-control-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "d-control-input__required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "d-control-input__label",
      for: $setup.props.id
    }, [
      _createTextVNode(_toDisplayString($setup.props.inputLabel), 1),
      ($setup.props.isRequired)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}