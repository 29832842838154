import { Initable } from '@/utils/classes'

const PAGE_SHIFT = 1

export class Pagination extends Initable {
  init(info) {
    this.info = info
  }

  hasNext() {
    throw new Error('Implement `hasNext` method in a subclass.')
  }

  hasPrevious() {
    throw new Error('Implement `hasPrevious` method in a subclass.')
  }

  next() {
    throw new Error('Implement `next` method in a subclass.')
  }

  previous() {
    throw new Error('Implement `previous` method in a subclass.')
  }

  getPage() {
    throw new Error('Implement `getPage` method in a subclass.')
  }
}

/**
 * A limit/offset pagination based style. For example:
 *
 * http://api.example.org/accounts/?limit=100
 * http://api.example.org/accounts/?offset=400&limit=100
 *
 * @export
 * @class LimitOffsetPagination
 * @extends {Pagination}
 */
const limitDefault = 9
export class LimitOffsetPagination extends Pagination {
  limitDefault = limitDefault

  limitMax = Infinity

  init(info, limit = null) {
    super.init(info)

    this.limit = limit || info.limit || this.limitDefault
    this.count = this.info.total
    this.offset = this.info.offset

    if (this.limit > this.limitMax) {
      throw new Error('Limit amount is exceeds limit maximum.')
    }
    const one = 1
    if (this.limit < one) {
      throw new Error('Limit amount cant\'t be less that 1.')
    }
  }

  hasNext() {
    return this.offset + this.limit < this.count
  }

  hasPrevious() {
    const zero = 0
    return this.offset > zero
  }

  next() {
    if (!this.hasNext()) {
      throw new Error('Before getting `next` check for a page existance.')
    }

    const one = 1
    return this.getParameters(this.getPageNumber() + one)
  }

  previous() {
    if (!this.hasPrevious()) {
      throw new Error('Before getting `previous` check for a page existance.')
    }
    const one = 1
    return this.getParameters(this.getPageNumber() - one)
  }

  getPageNumber(offset = this.offset, shift = PAGE_SHIFT) {
    return Math.floor(offset / this.limit) + shift
  }

  getPageRange(distance = null, current = this.getPageNumber()) {
    const min = 1
    const max = Math.max(min, Math.ceil(this.count / this.limit))
    if (null === distance || Infinity === distance) {
      return [min, max]
    }

    return [
      Math.max(min, current - distance),
      Math.min(max, current + distance),
    ]
  }

  getParameters(number = null) {
    let page = 1

    if (null !== number) {
      const [min, max] = this.getPageRange(Infinity, number)

      page = Math.min(max, Math.max(min, number))
    } else {
      page = this.getPageNumber()
    }

    return {
      offset: this.limit * (page - PAGE_SHIFT),
      limit: this.limit,
    }
  }
}
