import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'cart'

const CART_RETRIEVE = prefixAPI('/retrieve/', MODEL_PREFIX)
const CART_CHANGE = prefixAPI('/change/', MODEL_PREFIX)

export const cartRetrieveResource = createResource(CART_RETRIEVE)
export const cartChangeResource = createResource(CART_CHANGE, postResource)
