import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule, configure } from '.'
import {
  required,
  email,
  confirmed,
  min,
  minNoSpaces,
  max,
  alphaValidator,
  arrayLengthMax,
  customPassword,
  maskedPhone,
  maxValueValidatorNoSpaces,
  minValueValidatorNoSpaces,
  code,
  repeatSymbols,
  url,
  edrpouLegal,
} from './rules'

export default {
  install(app: App<Element>): any {
    configure({
      validateOnInput: true,
    })

    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)

    defineRule('required', required)
    defineRule('email', email)
    defineRule('confirmed', confirmed)
    defineRule('min', min)
    defineRule('max', max)
    defineRule('alpha', alphaValidator)
    defineRule('arrayLengthMax', arrayLengthMax)
    defineRule('customPassword', customPassword)
    defineRule('minNoSpaces', minNoSpaces)
    defineRule('maskedPhone', maskedPhone)
    defineRule('noSpacesMax', maxValueValidatorNoSpaces)
    defineRule('noSpacesMin', minValueValidatorNoSpaces)
    defineRule('code', code)
    defineRule('repeatSymbols', repeatSymbols)
    defineRule('url', url)
    defineRule('edrpouLegal', edrpouLegal)
  }
}
