import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'likes'

const LIKES_TOGGLE = prefixAPI('/toggle/', MODEL_PREFIX)

export const likesToggleResource = createResource(LIKES_TOGGLE, postResource)
