import { App } from 'vue'
import UserBar from './UserBar.vue'

const register = (app: App<Element>): void => {
  app
    .component('profile-user-bar', UserBar)
}

export default {
  register,
}
