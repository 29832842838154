import { App, defineAsyncComponent } from 'vue'
import UiModalContainer from './Container.vue'
import UiSimpleModalsContainer from './SimpleContainer.vue'

const ModalTrigger = defineAsyncComponent(() => import('./ModalTrigger.vue'))

const register = (app: App<Element>): void => {
  app
    .component('UiModalContainer', UiModalContainer)
    .component('UiSimpleModalContainer', UiSimpleModalsContainer)
    .component('ModalTrigger', ModalTrigger)
}

export default {
  register,
}
