import { App } from 'vue'
import Information from './Information.vue'
import Image from './Image.vue'
import Reviews from './Reviews.vue'
import Lessons from './Lessons.vue'
import Speaker from './Speaker.vue'
import SpeakerFade from './SpeakerFade.vue'
import SimpleFade from './SimpleFade.vue'
import Month from './Month.vue'
import Materials from './Materials.vue'
import Events from './Events.vue'

const register = (app: App<Element>): void => {
  app
    .component('slider-information', Information)
    .component('slider-image', Image)
    .component('slider-reviews', Reviews)
    .component('slider-lessons', Lessons)
    .component('slider-speaker', Speaker)
    .component('slider-speaker-fade', SpeakerFade)
    .component('slider-simple-fade', SimpleFade)
    .component('slider-month', Month)
    .component('slider-materials', Materials)
    .component('slider-events', Events)
}

export default {
  register,
}
