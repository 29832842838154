import { defineStore } from 'pinia'
import { userRetrieveResource } from '@/services/users.service'
import { User as UserClass } from '@/modules/users/base/user'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: new UserClass({}, false),
  }),

  getters: {
    getUserInfo: state => state.user,
  },

  actions: {
    async getUserData() {
      const { data: { item } } = await userRetrieveResource.execute({})

      this.setUserInfo(item)
    },

    setUserInfo(data: any) {
      this.user = new UserClass({ ...data })
    },
  },
})
