import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "s-language" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "s-language__image-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "s-language__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.languages, (item) => {
      return _createElementVNode("a", {
        class: _normalizeClass(["s-language__item", { 'is-active': $setup.currentLanguage.value === item.value }]),
        href: $setup.getLanguageLink(item.value)
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("img", {
            class: "s-language__image",
            src: item.icon
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)
      ], 10, _hoisted_2)
    }), 64))
  ]))
}