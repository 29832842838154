import {
  createResource,
  postResource,
  baseResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const MODEL_PREFIX = ''

const EVENT_FILTERS = prefixAPI('event-filter-choices/', MODEL_PREFIX)
const EVENT_CREATE = prefixAPI('event-request/create/', MODEL_PREFIX)
const EVENT_PERIOD_CREATE = prefixAPI('period-request/create/', MODEL_PREFIX)

const PROMOCODE_APPLY = prefixAPI('promocode-apply/', MODEL_PREFIX)

export const EVENT_AVAILABLE_FILTERS = prefixAPI('event-filter-choices/')

export const eventFiltersResource = createResource(EVENT_FILTERS)
export const eventRequestCreateResource = createResource(EVENT_CREATE, postResource)
export const eventPeriodRequestCreateResource = createResource(EVENT_PERIOD_CREATE, postResource)

export const promocodeApplyResource = createResource(PROMOCODE_APPLY, postResource)

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/ajax-events/')
const FILTERS_LIST_URL_POSTFIX = ''

export const eventCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX
], baseResource)
