export const dropdown = {
  mounted: (node: HTMLElement): any => {
    const handle = node.querySelector('.js-dropdown-handle') as HTMLElement

    handle.addEventListener('click', () => {
      const content = node.querySelector('.js-dropdown-content') as HTMLElement

      node.classList.toggle('is-active')
      content.classList.toggle('is-active')
    })
  }
}
