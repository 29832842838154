import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { reviewCatalogResource } from '@/services/review.service'
import useGlobal from '@/composables/useGlobal'
import { prefixLanguage } from '@/utils/urls'

interface FilterItem {
  name: string;
  queryKey?: string;
  items: any;
  type: string;
  title: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Catalog',
  props: {
  initial: {
    type: Object,
  },
  filters: {
    type: Object,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const urlPath = prefixLanguage('reviews')

const prependedFilters = ref<FilterItem[]>([
  {
    name: 'directions',
    queryKey: 'direction_ids',
    items: props.filters,
    type: 'multiselect',
    title: $t('Напрямок відгуку'),
  }
])

const __returned__ = { props, $t, urlPath, prependedFilters, ref, get reviewCatalogResource() { return reviewCatalogResource }, get useGlobal() { return useGlobal }, get prefixLanguage() { return prefixLanguage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})