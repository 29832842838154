import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, nextTick } from 'vue'
import { debounce } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxSingle',
  props: {
  value: {},
  filter: Object,
  config: Object,
  resetTrigger: Boolean,
  valueFromUrl: Object,
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const model = ref(props.modelValue)

const update = () => {
  emit('update:modelValue', model.value.toString())
}

const normalizeValue = (val: any) => {
  let value = null

  if (Array.isArray(val)) {
    value = val[0]
  } else if (val) {
    value = val.toString()
  }

  return value
}

const setValueFromUrl = (val: any) => {
  const items: string[] = props.filter.items
  const normalizedValue = normalizeValue(val)

  if (!normalizedValue) return

  const checked = items.find(el => normalizedValue === el.label.toString())

  if (!checked) return

  model.value = checked.label
}

const clickHandler = async (item: any, modelValueBeforeClick: any) => {
  if (modelValueBeforeClick === item.label) {
    await nextTick()

    model.value = ''
  }

  update()
}

const debounceClickHandler = debounce(clickHandler, 100)

watch(() => props.value, () => {
  setValueFromUrl(props.value)
}, { immediate: true })

watch(() => props.resetTrigger, () => model.value = '')

const __returned__ = { props, emit, model, update, normalizeValue, setValueFromUrl, clickHandler, debounceClickHandler, ref, watch, nextTick, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})