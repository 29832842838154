import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-like__item" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ui-like__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_heart = _resolveComponent("icon-heart")!
  const _component_modal_trigger = _resolveComponent("modal-trigger")!

  return ($setup.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["ui-like", { 'is-active': $setup.isLikedLocal }]),
        onClick: _withModifiers($setup.toggleLike, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_icon_heart)
        ])
      ], 2))
    : (_openBlock(), _createBlock(_component_modal_trigger, {
        key: 1,
        component: "Authentication/Login",
        classes: ['modal--size_sm']
      }, {
        default: _withCtx(({ open }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["ui-like", { 'is-active': $setup.isLikedLocal }]),
            onClick: _withModifiers(open, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_icon_heart)
            ])
          ], 10, _hoisted_2)
        ]),
        _: 1
      }))
}