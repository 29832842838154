import { defineComponent as _defineComponent } from 'vue'
import { materialsTeacherCatalogResource } from '@/services/materials.service'
import { prefixLanguage } from '@/utils/urls'


export default /*@__PURE__*/_defineComponent({
  __name: 'Catalog',
  props: {
  initial: {
    type: Object,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const urlPath = prefixLanguage('teacher-materials')

const __returned__ = { props, urlPath, get materialsTeacherCatalogResource() { return materialsTeacherCatalogResource }, get prefixLanguage() { return prefixLanguage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})