import { optionsT } from '@/router'

// import { createRoutes as createSettingsRoutes } from '@/components/Profile/modules/settings/router'
import { createRoutes as createEventRoutes } from '@/components/Profile/modules/event/router'
import { createRoutes as createCertificateRoutes } from '@/components/Profile/modules/certificate/router'
import { createRoutes as createErrorsRoutes } from '@/components/Profile/modules/errors/router'

export function createRoutes(options: optionsT): any {
  return [
    // ...createSettingsRoutes(options),
    ...createEventRoutes(options),
    ...createCertificateRoutes(options),
    ...createErrorsRoutes(options),
  ]
}
