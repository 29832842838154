import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "feedback-form" }
const _hoisted_2 = { class: "feedback-form__wrapper" }
const _hoisted_3 = { class: "feedback-form__main" }
const _hoisted_4 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "error-label" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_11 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_12 = { class: "error-label" }
const _hoisted_13 = { class: "feedback-form__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.send }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "feedback-form__image-wrapper" }, [
            _createElementVNode("img", { src: '/static/app/images/question.png' })
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
            ]), 2),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_control_input, {
                  name: "firstName",
                  type: "text",
                  rules: "required|max:45",
                  errors: errors,
                  "input-label": $setup.$t("Ваше ім’я"),
                  placeholder: $setup.$t("Введіть ім’я"),
                  modelValue: $setup.formdata.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.firstName) = $event))
                }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_control_input, {
                  name: "lastName",
                  type: "text",
                  rules: "required|max:45",
                  errors: errors,
                  "input-label": $setup.$t("Ваше Прізвище"),
                  placeholder: $setup.$t("Введіть прізвище"),
                  modelValue: $setup.formdata.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.lastName) = $event))
                }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
              ]),
              _createVNode(_component_VeeError, { name: "name" }, {
                default: _withCtx(({ message }) => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(message), 1)
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_control_input, {
                  name: "email",
                  errors: errors,
                  "input-label": $setup.$t("Ваш E-mail"),
                  placeholder: $setup.$t("Введіть E-mail"),
                  type: "email",
                  rules: "required|email",
                  modelValue: $setup.formdata.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formdata.email) = $event))
                }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_control_input, {
                  component: "SimpleTextarea",
                  name: "message",
                  type: "text",
                  rules: "min:5|max:1000",
                  rows: "4",
                  errors: errors,
                  "input-label": $setup.$t("Ваше запитання"),
                  placeholder: $setup.$t("Напишіть ваше питання"),
                  modelValue: $setup.formdata.message,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formdata.message) = $event))
                }, null, 8, ["errors", "input-label", "placeholder", "modelValue"])
              ]),
              _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
                default: _withCtx(({ message }) => [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(message), 1)
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_control_button, {
            disabled: $setup.isLoad,
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.$t("Надіслати")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }))
}