import { App } from 'vue'
import Form from './Form.vue'

const register = (app: App<Element>): void => {
  app
    .component('payment-form', Form)
}

export default {
  register,
}
