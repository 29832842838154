import { App } from 'vue'
import ButtonAdd from './ButtonAdd.vue'
import ButtonDetails from './ButtonDetails.vue'

const register = (app: App<Element>): void => {
  app
    .component('cart-button-add', ButtonAdd)
    .component('cart-button-details', ButtonDetails)
}

export default {
  register,
}
