import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch, defineAsyncComponent, nextTick } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { examsFeedbackCreateResource } from '@/services/exams.service'
import { useShowModal } from '@/composables/useModalOpener'
import { useUserStore } from '@/store/user'
import useGlobal from '@/composables/useGlobal'
import { clearPhone, prettifyPhone } from '@/utils/transformers'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormCallbackSimple',
  props: {
  grid: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const PrivacyPolicy = defineAsyncComponent(() => import('@/components/Authentication/PrivacyPolicy'))

const props = __props

const { $t } = useGlobal()
const userStore = useUserStore()
const isLoad = ref(false)

const formdata = reactive({
  name: '',
  email: '',
  phone: '',
  city: '',
  pageFrom: window.location.href
})

const prefillUserInfo = val => {
  if (!window.isAuthenticated) return

  formdata.name = `${val.info.firstName} ${val.info.lastName}`
  formdata.email = val.info.email

  let userPhone = val.info.phone

  if (userPhone) {
    userPhone = prettifyPhone(userPhone)
  }

  formdata.phone = userPhone
}

watch(() => userStore.getUserInfo, val => {
  prefillUserInfo(val)
}, { immediate: true, deep: true })

const resetForm = () => {
  prefillUserInfo(userStore.getUserInfo)
}

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  preparedFormdata.phone = clearPhone(preparedFormdata.phone)

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  examsFeedbackCreateResource.execute({}, preparedFormdata).then(() => {
    const message = {
      title: $t('Спасибо за вопрос'),
      text: $t('Скоро наш менеджер свяжется с Вами'),
    }

    useShowModal(message)

    control.resetForm()

    nextTick(() => {
      resetForm()
    })
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

const __returned__ = { PrivacyPolicy, props, $t, userStore, isLoad, formdata, prefillUserInfo, resetForm, prepareFormdata, send, ref, reactive, watch, defineAsyncComponent, nextTick, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get examsFeedbackCreateResource() { return examsFeedbackCreateResource }, get useShowModal() { return useShowModal }, get useUserStore() { return useUserStore }, get useGlobal() { return useGlobal }, get clearPhone() { return clearPhone }, get prettifyPhone() { return prettifyPhone } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})