import { App } from 'vue'
import { OnClickOutside } from '@vueuse/components'
import Dropdown from './Dropdown.vue'
import SidebarToggler from './SidebarToggler.vue'
import AsideToggler from './AsideToggler.vue'
import Rating from './Rating.vue'
import ClampText from './ClampText.vue'
import ClampContent from './ClampContent.vue'
import Cropper from './Cropper.vue'
import Like from './Like.vue'
import ScrollToTop from './ScrollToTop.vue'
import Percentage from './Percentage.vue'
import Video from './Video.vue'
import VideoCircle from './VideoCircle.vue'
import Audio from './Audio.vue'
import ZipLoader from './ZipLoader.vue'
import Tabs from './Tabs.vue'
import LanguageSwitch from './LanguageSwitch.vue'

const register = (app: App<Element>): void => {
  app
    .component('ui-click-outside', OnClickOutside)
    .component('ui-dropdown', Dropdown)
    .component('ui-sidebar-toggler', SidebarToggler)
    .component('ui-aside-toggler', AsideToggler)
    .component('ui-rating', Rating)
    .component('ui-clamp-text', ClampText)
    .component('ui-clamp-content', ClampContent)
    .component('ui-cropper', Cropper)
    .component('ui-like', Like)
    .component('ui-scroll-to-top', ScrollToTop)
    .component('ui-percentage', Percentage)
    .component('ui-video', Video)
    .component('ui-video-circle', VideoCircle)
    .component('ui-audio', Audio)
    .component('ui-zip-loader', ZipLoader)
    .component('ui-tabs', Tabs)
    .component('ui-language-switch', LanguageSwitch)
}

export default {
  register,
}
