import { defineComponent as _defineComponent } from 'vue'
import { useCartStore } from '@/store/cart'


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonDetails',
  setup(__props, { expose: __expose }) {
  __expose();

const cartStore = useCartStore()

const __returned__ = { cartStore, get useCartStore() { return useCartStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})