import { App } from 'vue'
import EmailSet from './EmailSet.vue'
import Login from './Login.vue'

const register = (app: App<Element>): void => {
  app
    .component('auth-email-set', EmailSet)
    .component('auth-login', Login)
}

export default {
  register,
}
