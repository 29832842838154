import { ref } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { loginResource } from '@/services/authenticate.service'

interface Result {
  send: Promise<void | boolean>;
  isLoad: boolean;
}

export default function (): Promise<Result> | any {
  const isLoad = ref<boolean>(false)

  const send = async (sendData: object, control: any): Promise<void | boolean> => {
    control.setFieldError('nonFieldErrors', '')

    if (isLoad.value) return false

    isLoad.value = true

    loginResource.execute({}, sendData).then(() => {
      const redirect = getRedirectUrl()
      const wind: Window = window

      if (redirect) {
        wind.location = redirect
      } else {
        wind.location.reload()
      }
    }).catch(async (e: object) => {
      const parsed = await useErrorsGetter(e)

      useParsedSetter(parsed, control.setFieldError)
    }).finally(() => {
      isLoad.value = false
    })
  }

  const getRedirectUrl = (): string | any => {
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get('next')

    return redirect
  }

  return { send, isLoad }
}