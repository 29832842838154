import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, null, {
    default: _withCtx(({ close, params }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.componentExtactor), {
        params: params,
        close: close,
        "component-path": $setup.local,
        "onHook:created": ($event: any) => ($setup.save(params))
      }, null, 40, ["params", "close", "component-path", "onHook:created"]))
    ]),
    _: 1
  }))
}