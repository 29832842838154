import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'feedback'

const FEEDBACK_CREATE = prefixAPI('/create/', MODEL_PREFIX)

export const feedbackCreateResource = createResource(FEEDBACK_CREATE, postResource)
