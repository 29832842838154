<template>
  <render ref="content" />
</template>

<script setup>
import { ref, useSlots } from 'vue'

const slots = useSlots()

const props = defineProps({
  active: {
    default: 0,
  },
})

const activeItem = ref(props.active)

const activate = item => {
  activeItem.value = item
}

const render = () => slots.default({
  activeItem: activeItem.value,
  activate: activate,
})
</script>
