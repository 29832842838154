import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { useWindowScroll } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollToTop',
  setup(__props, { expose: __expose }) {
  __expose();

const isVisible = ref(false)

const { y } = useWindowScroll()

watch(() => y.value, () => {
  updateScrollTopVisibility()
})

const updateScrollTopVisibility = () => {
  const offset = 150

  if (window.pageYOffset > offset) {
    isVisible.value = true
  } else {
    isVisible.value = false
  }
}

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const __returned__ = { isVisible, y, updateScrollTopVisibility, scrollTop, ref, watch, get useWindowScroll() { return useWindowScroll } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})