import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'exams'

const EXAMS_FEEDBACK_CREATE = prefixAPI('/feedback-create/', MODEL_PREFIX)

export const examsFeedbackCreateResource = createResource(EXAMS_FEEDBACK_CREATE, postResource)
