import urlTemplate from 'url-template'

export const RANGE_PREFIX = '_after'
export const RANGE_POSTFIX = '_before'

const RANGE_MAP = {
  0: RANGE_PREFIX,
  1: RANGE_POSTFIX,
}

const filterUrl = urlTemplate.parse('/{;filters*}/')

export function prepareRangeFilters(filters) {
  return Object.keys(filters).reduce((acc, key) => {
    if (key.includes(RANGE_PREFIX) || key.includes(RANGE_POSTFIX)) {
      Object.keys(RANGE_MAP).forEach(index => {
        const clearKey = key.replace(RANGE_MAP[index], '')

        if (key.includes(RANGE_MAP[index])) {
          if (!acc[clearKey]) {
            acc[clearKey] = []
          }

          const [firstValue] = filters[key]

          acc[clearKey][index] = firstValue
        }
      })
    } else {
      acc[key] = filters[key]
    }

    return acc
  }, {})
}

export function friendlyUrlParser() {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl = {}

  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]

    const hashes = path.split('filters/')[1].split(';')

    hashes.map(hash => {
      const [key, val] = hash.split('=')

      parsedUrl[key] = []

      if (val.indexOf(',') > zero) {
        const newVal = val.split(',')
        newVal.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
    })
  }

  const preparedFilters = prepareRangeFilters(parsedUrl)

  return preparedFilters
}

export function reduceFiltersData(data) {
  const reducedData = Object.keys(data).reduce((acc, key) => {
    if (data[key]) {
      if (Array.isArray(data[key]) && data[key].length) {
        acc[key] = data[key].map(option => {
          if (option.slug) {
            return option.slug
          }
          if (option.id) {
            return option.id
          }
          return option
        })
      } else if ('string' === typeof data[key]) {
        acc[key] = data[key]
      } else {
        acc[key] = data[key].slug
        if (data[key].id) {
          acc[key] = data[key].id
        }
      }
    }
    return acc
  }, {})
  return reducedData
}

export const checkFilter = data => {
  return Object.keys(data).every(key => !data[key])
}

export function createFriendlyUrl(data) {
  let url = filterUrl.expand({ filters: data })
  url = url.replace(/;/, '')
    .replace(/%2C/g, ',')
    .replace(/\//, '')
  if ('/' === url) {
    url = ''
  }
  return url
}

export function prettifyUrl(params, base) {
  let fullUrl
  const keys = Object.keys(params.filters)
  const zero = 0
  const length = 2

  for (let x = 0; x <= keys.length; x++) {
    if (params.filters[keys[x]] !== undefined) {
      if (zero === params.filters[keys[x]].length) {
        delete params.filters[keys[x]]
      }
    }
  }

  let url = filterUrl.expand(params)

  url = url.replace(/;/, '')
  url = url.replace(/%2C/g, ',')

  if (url.length > length) {
    fullUrl = `/${base}/filters${url}`
  } else {
    fullUrl = `/${base}/`
  }

  if (params.page && params.page !== 1) {
    fullUrl = `${fullUrl}page/${params.page}/`
  }

  window.history.pushState({ url: fullUrl }, '', `${fullUrl}${window.location.search}`)
}
