import {
  emailErrMessage,
  requiredErrMessage,
  confirmedErrMessage,
  minErrMessage,
  maxErrMessage,
  alphaErrMessage,
  arrayLengthMaxErrMessage,
  customPasswordErrMessage,
  minNoSpacesErrMessage,
  maskedPhoneErrorMessage,
  noSpacesMaxErrorMessage,
  noSpacesMinErrorMessage,
  codeErrMessage,
  repeatSymbolsErrorMessage,
  urlErrorMessage,
  edrpouLegalErrorMessage,
} from '@/main'

export const alpha: { [k: string]: RegExp } = {
  en: /^[A-Z]*$/i,
  cs: /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]*$/i,
  da: /^[A-ZÆØÅ]*$/i,
  de: /^[A-ZÄÖÜß]*$/i,
  es: /^[A-ZÁÉÍÑÓÚÜ]*$/i,
  fr: /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]*$/i,
  it: /^[A-Z\xC0-\xFF]*$/i,
  lt: /^[A-ZĄČĘĖĮŠŲŪŽ]*$/i,
  nl: /^[A-ZÉËÏÓÖÜ]*$/i,
  hu: /^[A-ZÁÉÍÓÖŐÚÜŰ]*$/i,
  pl: /^[A-ZĄĆĘŚŁŃÓŻŹ]*$/i,
  pt: /^[A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ]*$/i,
  ru: /^[А-ЯЁ]*$/i,
  sk: /^[A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ]*$/i,
  sr: /^[A-ZČĆŽŠĐ]*$/i,
  sv: /^[A-ZÅÄÖ]*$/i,
  tr: /^[A-ZÇĞİıÖŞÜ]*$/i,
  uk: /^[А-ЩЬЮЯЄІЇҐ]*$/i,
  ar: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]*$/,
  az: /^[A-ZÇƏĞİıÖŞÜ]*$/i,
  ug: /^[A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ]*$/i,
}

export const alphaSpaces: { [k: string]: RegExp } = {
  en: /^[A-Z\s]*$/i,
  cs: /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ\s]*$/i,
  da: /^[A-ZÆØÅ\s]*$/i,
  de: /^[A-ZÄÖÜß\s]*$/i,
  es: /^[A-ZÁÉÍÑÓÚÜ\s]*$/i,
  fr: /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\s]*$/i,
  it: /^[A-Z\xC0-\xFF\s]*$/i,
  lt: /^[A-ZĄČĘĖĮŠŲŪŽ\s]*$/i,
  nl: /^[A-ZÉËÏÓÖÜ\s]*$/i,
  hu: /^[A-ZÁÉÍÓÖŐÚÜŰ\s]*$/i,
  pl: /^[A-ZĄĆĘŚŁŃÓŻŹ\s]*$/i,
  pt: /^[A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ\s]*$/i,
  ru: /^[А-ЯЁ\s]*$/i,
  sk: /^[A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ\s]*$/i,
  sr: /^[A-ZČĆŽŠĐ\s]*$/i,
  sv: /^[A-ZÅÄÖ\s]*$/i,
  tr: /^[A-ZÇĞİıÖŞÜ\s]*$/i,
  uk: /^[А-ЩЬЮЯЄІЇҐ\s]*$/i,
  ar: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ\s]*$/,
  az: /^[A-ZÇƏĞİıÖŞÜ\s]*$/i,
  ug: /^[A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ\s]*$/i,
}

export const alphanumeric: { [k: string]: RegExp } = {
  en: /^[0-9A-Z]*$/i,
  cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]*$/i,
  da: /^[0-9A-ZÆØÅ]$/i,
  de: /^[0-9A-ZÄÖÜß]*$/i,
  es: /^[0-9A-ZÁÉÍÑÓÚÜ]*$/i,
  fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]*$/i,
  it: /^[0-9A-Z\xC0-\xFF]*$/i,
  lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ]*$/i,
  hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ]*$/i,
  nl: /^[0-9A-ZÉËÏÓÖÜ]*$/i,
  pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ]*$/i,
  pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ]*$/i,
  ru: /^[0-9А-ЯЁ]*$/i,
  sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ]*$/i,
  sr: /^[0-9A-ZČĆŽŠĐ]*$/i,
  sv: /^[0-9A-ZÅÄÖ]*$/i,
  tr: /^[0-9A-ZÇĞİıÖŞÜ]*$/i,
  uk: /^[0-9А-ЩЬЮЯЄІЇҐ]*$/i,
  ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]*$/,
  az: /^[0-9A-ZÇƏĞİıÖŞÜ]*$/i,
  ug: /^[0-9A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ]*$/i,
}

export const alphaDash: { [k: string]: RegExp } = {
  en: /^[0-9A-Z_-]*$/i,
  cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ_-]*$/i,
  da: /^[0-9A-ZÆØÅ_-]*$/i,
  de: /^[0-9A-ZÄÖÜß_-]*$/i,
  es: /^[0-9A-ZÁÉÍÑÓÚÜ_-]*$/i,
  fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ_-]*$/i,
  it: /^[0-9A-Z\xC0-\xFF_-]*$/i,
  lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ_-]*$/i,
  nl: /^[0-9A-ZÉËÏÓÖÜ_-]*$/i,
  hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ_-]*$/i,
  pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ_-]*$/i,
  pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ_-]*$/i,
  ru: /^[0-9А-ЯЁ_-]*$/i,
  sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ_-]*$/i,
  sr: /^[0-9A-ZČĆŽŠĐ_-]*$/i,
  sv: /^[0-9A-ZÅÄÖ_-]*$/i,
  tr: /^[0-9A-ZÇĞİıÖŞÜ_-]*$/i,
  uk: /^[0-9А-ЩЬЮЯЄІЇҐ_-]*$/i,
  ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ_-]*$/,
  az: /^[0-9A-ZÇƏĞİıÖŞÜ_-]*$/i,
  ug: /^[0-9A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ_-]*$/i,
}

export const getLocale = (params?: { locale?: string } | [string | undefined]): string | undefined => {
  if (!params) {
    return undefined
  }

  return Array.isArray(params) ? params[0] : params.locale
}

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string): unknown {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

const required = (value: unknown): boolean | string => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || !value) {
    return requiredErrMessage
  }
  return true
}

const email = (value: any | string): boolean | string => {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!EMAILREG.test(value)) {
    return emailErrMessage
  }

  return true
}

const confirmed = (value: any | string, [target]: string): boolean => {
  if (value === target) {
    return true
  }
  return confirmedErrMessage
}

const min = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return `${minErrMessage} ${length}`
  }
}

const minNoSpaces = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).replace(/\s/g,'').length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return `${minNoSpacesErrMessage} ${length}`
  }
}

const maskedPhone = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).replace(/[^\d]/g,'').length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return maskedPhoneErrorMessage
  }
}

const max = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => max(val, { length }))
  }

  if (preparedValue <= length) {
    return true
  } else {
    return `${maxErrMessage} ${length}`
  }
}

const alphaValidator = (value: unknown, params: [string | undefined] | { locale?: string }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }
  const locale = getLocale(params)
  if (Array.isArray(value)) {
    return value.every(val => alphaValidator(val, { locale }))
  }

  const valueAsString = String(value)
  const valueMatch = Object.keys(alpha).some(loc => alpha[loc].test(valueAsString))
  // Match at least one locale.
  if (!locale) {
    if (valueMatch) {
      return true
    } else {
      return alphaErrMessage
    }
  }
  return (alpha[locale] || alpha.en).test(valueAsString)
}

const arrayLengthMax = (value: unknown, params: [number | string] | { length: string | number }): boolean | string => {
  // Normalize the length value
  const length = Number(getSingleParam(params, 'length'))
  const val = value as ArrayLike<unknown>
  const preparedValue = val.length

  if (isNullOrUndefined(value)) {
    return false
  }

  if (!preparedValue) {
    value = Array.from(val)
  }

  if (preparedValue <= length) {
    return true
  } else {
    return `${arrayLengthMaxErrMessage} ${length}`
  }
}

const repeatSymbols = (value: any | string, params: [number | string]): boolean | string => {
  if (!value || !value.length) {
    return true
  }
  const length = Number(getSingleParam(params, 'repeatSymbols'))

  const REPEAT_SYMBOLS_VALIDATION = new RegExp(String.raw `(.)\1{${length}}`, 'g')

  if (REPEAT_SYMBOLS_VALIDATION.test(value)) {
    return `${repeatSymbolsErrorMessage} ${length}`
  }

  return true
}

const customPassword = (value: any | string): boolean | string => {
  if (!value || !value.length) {
    return true
  }

  const PASSWORD_VALIDATION = /(?=.*[#?!@$%^&*-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{8,14})(^[、_=\\\[\];'",-/./~`!@#$%^&*()+|?><“:{}A-Za-z0-9]*$)/g

  if (PASSWORD_VALIDATION.test(value)) {
    return customPasswordErrMessage
  }

  return true
}

const maxValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const max = Number(getSingleParam(params, 'max'))
  const preparedValue = Number(value.replace(/ /g, ''))

  if (preparedValue <= max) {
    return true
  } else {
    return `${noSpacesMaxErrorMessage} ${max}`
  }
}

const minValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const min = Number(getSingleParam(params, 'min'))
  const preparedValue = Number(value.replace(/ /g, ''))

  if (preparedValue >= min) {
    return true
  } else {
    return `${noSpacesMinErrorMessage} ${min}`
  }
}

const code = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return codeErrMessage
  }
}

const url = (value: string): boolean | string => {
  const reg = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

  const isValid = reg.test(value)

  if (isValid) {
    return true
  } else {
    return urlErrorMessage
  }
}

const edrpouLegal = (value: string): boolean | string => {
  const isValid = /^\d{8}$/.test(value) || /^\d{9}$/.test(value) || /^\d{12}$/.test(value)

  if (isValid) {
    return true
  } else {
    return edrpouLegalErrorMessage
  }
}

export {
  required,
  email,
  confirmed,
  min,
  max,
  alphaValidator,
  arrayLengthMax,
  customPassword,
  minNoSpaces,
  maskedPhone,
  maxValueValidatorNoSpaces,
  minValueValidatorNoSpaces,
  code,
  repeatSymbols,
  url,
  edrpouLegal,
}
