const innerWidth = window.innerWidth
const checkIsMobile = () => 768 > innerWidth

const Visible = function (target, parent) {
  // Все позиции элемента
  const targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      left: window.pageXOffset + target.getBoundingClientRect().left,
      right: window.pageXOffset + target.getBoundingClientRect().right,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.pageXOffset + document.documentElement.clientWidth,
      bottom: window.pageYOffset + document.documentElement.clientHeight
    }

    // if (targetPosition.top < windowPosition.top) {
    //   console.log('не влезло сверху')
    // }
    // if (targetPosition.bottom > windowPosition.bottom) {
    //   console.log('не влезло снизу')
    // }
    if (targetPosition.right > windowPosition.right) {
      // console.log('не влезло справа')
      const diff = targetPosition.right - windowPosition.right
      let width = parent.getBoundingClientRect()['width']
      width = width / 2
      // console.log(width)
      target.style.setProperty('left', `${width - diff}px`)
    }
    if (targetPosition.left < windowPosition.left) {
      // console.log('не влезло слева')
      const diff = windowPosition.left - targetPosition.left
      let width = parent.getBoundingClientRect()['width']
      width = width / 2
      // console.log(width)
      target.style.setProperty('left', `${width + diff}px`)
    }
}

export const partnersDots = number => {
  // console.log('partnersDots')
  const dots = [...document.getElementsByClassName('region-marker')]
  const map = document.getElementById('ukraine-map')
  if (map) {
    const k = map.clientWidth / map.width.animVal.value
    // console.log('map.clientWidth', map.clientWidth)
    // console.log('map.width', map.width)
    // console.log('k', k)
    const regions = [...map.getElementsByClassName('land')]
    // console.log(regions)
    // console.log(dots)
    regions.forEach(e => e.classList.remove('is-active'))
    dots.forEach(dot => {
      dot.classList.remove('is-active')
      const {
        region,
        slide,
        x,
        y,
      } = dot.dataset
      // console.log(dot.dataset)
      // eslint-disable-next-line eqeqeq
      if (slide != number) return
      const parent = document.getElementById(region)
      parent.classList.add('is-active')
      const position = parent.getBBox()
      const position_x = position.width / 100 * x
      const position_y = position.height / 100 * y
      const left = (position.x + position_x) * k
      const top = (position.y + position_y) * k
      dot.classList.add('is-active')
      dot.style.setProperty('--left', `${left}px`)
      dot.style.setProperty('--top', `${top}px`)
      // const 
      if(!checkIsMobile()) {
        dot.addEventListener("mouseover", event => {
          // console.log("Mouse in (over)")
          const {
            id,
          } = event.currentTarget.dataset
          // console.log(id)
          event.currentTarget.classList.add('is-focus')
          const tooltip = event.currentTarget.getElementsByClassName('region-tooltip')[0]
          // console.log(tooltip)
          Visible(tooltip, event.currentTarget)
        })
        dot.addEventListener("mouseout", event => {
          // console.log("mouseout")
          const {
            id,
          } = event.currentTarget.dataset
          // console.log(id)
          event.currentTarget.classList.remove('is-focus')
        })
      } else {
        dot.addEventListener("click", event => {
          // console.log("click")
          const {
            id,
          } = event.currentTarget.dataset
          // console.log(id)
          const focusedMarkers = [...document.getElementsByClassName('region-marker is-focus')]
          event.currentTarget.classList.toggle('is-focus')
          // console.log('focusedMarkers', focusedMarkers)
          focusedMarkers.forEach(e => e.classList.remove('is-focus'))
          const tooltip = event.currentTarget.getElementsByClassName('region-tooltip')[0]
          // console.log(tooltip)
          Visible(tooltip, event.currentTarget)
        })
      }
      // dot
      // console.log('обработали точку')
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  partnersDots(0)
})