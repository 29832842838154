import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, useSlots } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarToggler',
  setup(__props) {

const slots = useSlots()

const isActive = ref(false)

const toggle = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = !isActive.value

  if (isActive.value) {
    bodyEl.classList.add('is-overflow')
  } else {
    bodyEl.classList.remove('is-overflow')
  }
}

const render = () => slots.default({
  isActive: isActive.value,
  toggle,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})