import { App } from 'vue'
import FormCallbackSimple from './FormCallbackSimple.vue'

const register = (app: App<Element>): void => {
  app
    .component('exams-form-callback-simple', FormCallbackSimple)
}

export default {
  register,
}
