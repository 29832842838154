import {
  baseResource,
} from '@/resource/resource'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const CATALOG_TEACHER_LIST_URL_PREFIX = prefixLanguage('/ajax-teacher-materials/')
const CATALOG_LIST_URL_PREFIX = prefixLanguage('/ajax-materials/')
const CATALOG_DETAILS_LIST_URL_PREFIX = prefixLanguage('/ajax-materials/category/')

export const materialsTeacherCatalogResource = friendlyUrlGenerator([
  CATALOG_TEACHER_LIST_URL_PREFIX, ''
], baseResource)

export const materialsCatalogResource = friendlyUrlGenerator([
  CATALOG_LIST_URL_PREFIX, ''
], baseResource)

export const materialsDetailsCatalogResource = friendlyUrlGenerator([
  CATALOG_DETAILS_LIST_URL_PREFIX, '',
], baseResource)
