import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "star-rating__wrapper" }
const _hoisted_2 = ["onMouseover", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_star_empty = _resolveComponent("icon-star-empty")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["star-rating", { 'is-readonly': $setup.props.readonly }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["star-rating__item", [{ 'is-active': $setup.props.rating >= item || $setup.highlightedIndex >= item || $setup.selectedStar >= item }, $props.size ? `star-rating__item--size_${$props.size}` : '' ]]),
          key: item,
          onMouseover: ($event: any) => ($setup.setHighlightedItem(item)),
          onMouseleave: _cache[0] || (_cache[0] = ($event: any) => ($setup.setHighlightedItem(null))),
          onClick: _withModifiers(($event: any) => ($setup.selectStar(item)), ["prevent"])
        }, [
          _createVNode(_component_icon_star_empty)
        ], 42, _hoisted_2)
      }), 64))
    ])
  ], 2))
}