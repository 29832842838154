import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    class: "swiper--variant_1",
    modules: $setup.modules,
    autoHeight: false,
    spaceBetween: 20,
    speed: 500,
    breakpoints: $setup.breakpoints,
    slidesPerView: 3,
    pagination: { clickable: true, el: '.js-events-pagination' },
    navigation: { nextEl: '.js-events-next', prevEl: '.js-events-prev' }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}