import { App } from 'vue'
import { tableWrapper } from './table'
import { scrollToEventForm } from './scrollToEventForm'
import { dropdown } from './dropdown'
import { accordion } from './accordion'

const register = (app: App<Element>): void => {
  app.directive('table-wrapper', tableWrapper)
  app.directive('event-form', scrollToEventForm)
  app.directive('dropdown', dropdown)
  app.directive('accordion', accordion)
}

export default {
  register,
}
