<template>
  <render />
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'

const slots = useSlots()

const isActive = ref(false)

const toggle = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = !isActive.value

  if (isActive.value) {
    bodyEl.classList.add('is-overflow')
  } else {
    bodyEl.classList.remove('is-overflow')
  }
}

const render = () => slots.default({
  isActive: isActive.value,
  toggle,
})
</script>