import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { eventCatalogResource } from '@/services/event.service'
import useGlobal from '@/composables/useGlobal'
import { prefixLanguage } from '@/utils/urls'


export default /*@__PURE__*/_defineComponent({
  __name: 'Catalog',
  props: {
  initial: {
    type: Object,
  },
  eventTypes: {
    type: Array,
  },
  sectors: {
    type: Array,
  },
  directions: {
    type: Array,
  },
  speakers: {
    type: Array,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const urlPath = prefixLanguage('events')

const prependedFilters = ref([
  {
    name: 'event_type',
    queryKey: 'event_types_ids',
    items: props.eventTypes,
    type: 'multiselect',
    title: $t('Тип івенту'),
  },
  {
    name: 'sectors',
    queryKey: 'sectors_ids',
    items: props.sectors,
    type: 'multiselect',
    title: $t('Сектор івенту'),
  },
  {
    name: 'directions',
    queryKey: 'directions',
    items: props.directions,
    type: 'multiselect',
    title: $t('Напрямок івенту'),
  },
  {
    name: 'speaker',
    queryKey: 'speaker',
    items: props.speakers,
    type: 'multiselect',
    title: $t('Спікер івенту'),
  },
])

const appendedFilters = ref([
  {
    name: 'date',
    prefix: 'before',
    postfix: 'after',
    type: 'datepicker_range',
    title: $t('Початок івенту'),
  },
  {
    name: 'registration_completed',
    queryKey: 'registration_completed',
    type: 'checkbox_single',
    items: [
      { title: $t('Реєстрація триває'), label: false },
      { title: $t('Реєстрація завершена'), label: true },
    ],
  },
  {
    name: 'payment_type',
    queryKey: 'payment_type',
    type: 'checkbox_single',
    items: [
      { title: $t('Безкоштовні'), label: 'free' },
      { title: $t('Платні'), label: 'paid' },
    ],
  },
])

const __returned__ = { props, $t, urlPath, prependedFilters, appendedFilters, ref, get eventCatalogResource() { return eventCatalogResource }, get useGlobal() { return useGlobal }, get prefixLanguage() { return prefixLanguage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})