import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, useSlots, watch } from 'vue'

interface Props {
  initialActive?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Dropdown',
  props: {
    initialActive: { type: Boolean }
  },
  setup(__props: any) {

const slots = useSlots()
const props = __props

const isActive = ref(false)

const hide = () => {
  isActive.value = false
}

const show = () => {
  isActive.value = true
}

const toggle = () => {
  isActive.value = !isActive.value
}

watch(() => props.initialActive, () => {
  isActive.value = props.initialActive
})

const render = () => slots.default({
  isActive: isActive.value,
  hide,
  show,
  toggle,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})