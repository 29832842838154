import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'payments'

const PAYMENTS_CREATE = prefixAPI('/create/', MODEL_PREFIX)

export const paymentsCreateResource = createResource(PAYMENTS_CREATE, postResource)