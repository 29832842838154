import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { likesToggleResource } from '@/services/likes.service'
import useGlobal from '@/composables/useGlobal'
import { useConfirmWithPromise } from '@/composables/useModalOpener'

interface Props {
  isLiked: boolean,
  type: string,
  itemId: string | number,
  needRemoveConfirm?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Like',
  props: {
    isLiked: { type: Boolean },
    type: {},
    itemId: {},
    needRemoveConfirm: { type: Boolean }
  },
  emits: ['update'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const isAuthenticated = window.isAuthenticated

const props = __props

const emit = __emit

const { $t } = useGlobal()

const isLikedLocal = ref(props.isLiked)

const toggleLike = async () => {
  if (isLikedLocal.value && props.needRemoveConfirm) {
    const info = {
      title: $t('Чи підтверджуєте ви дію?'),
    }

    await useConfirmWithPromise(info)
  }

  const formdata = { id: props.itemId, type: props.type }

  likesToggleResource.execute({}, formdata).then(() => {
    isLikedLocal.value = !isLikedLocal.value

    emit('update', isLikedLocal.value)
  })
}

const __returned__ = { isAuthenticated, props, emit, $t, isLikedLocal, toggleLike, ref, get likesToggleResource() { return likesToggleResource }, get useGlobal() { return useGlobal }, get useConfirmWithPromise() { return useConfirmWithPromise } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})