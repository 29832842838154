import { defineComponent as _defineComponent } from 'vue'
import { ref, defineAsyncComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleContainer',
  setup(__props, { expose: __expose }) {
  __expose();

const local = ref(null)
const componentExtactor = defineAsyncComponent(() => import(`@/components/Modals/Wrapper`))

const save = ({ component }) => {
  local.value = component
}

const __returned__ = { local, componentExtactor, save, ref, defineAsyncComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})