<template>
  <render ref="content" />
</template>

<script setup>
import { ref, useSlots, onMounted } from 'vue'

const slots = useSlots()
const content = ref('')
const clamped = ref(false)

onMounted(() => {
  const height = content.value.nextSibling?.firstChild?.offsetHeight
  const windowWidth = window.innerWidth

  let textHeight = 64

  if (768 >= windowWidth) {
    textHeight = 60
  }

  if (windowWidth >= 1440) {
    textHeight = 70
  }

  if (height && height > textHeight) {
    clamped.value = true
  }
})

const render = () => slots.default({
  clamped: clamped.value,
})
</script>