import SelectWidget from './widgets/SelectWidget.vue'
import DatepickerWidget from './widgets/DatepickerWidget.vue'
import CheckboxSingle from './widgets/CheckboxSingle.vue'

export const WIDGETS_MAP = {
  'multiselect': {
    component: SelectWidget,
    config: {
      multiple: true,
    },
  },
  'select': {
    component: SelectWidget,
    config: {
      multiple: false,
    },
  },
  'datepicker_range': {
    component: DatepickerWidget,
    config: {
      range: true,
    },
  },
  'checkbox_single': {
    component: CheckboxSingle,
  },
}
