import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, KeepAlive as _KeepAlive, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "g-container g-container--space_none g-container--fit_md" }
const _hoisted_2 = { class: "g-container g-container--space_none g-container--fit_xxs" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_5 = { class: "g-row" }
const _hoisted_6 = { class: "g-cell" }
const _hoisted_7 = { class: "d-control-radio d-control-radio--variant_flex" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "d-control-radio__content" }
const _hoisted_10 = { class: "ds-caption ds-caption--size_sm" }
const _hoisted_11 = { class: "ds-panel ds-panel--space_xs" }
const _hoisted_12 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_13 = {
  key: 0,
  class: "ds-caption ds-caption--size_xs ds-caption--color_relief-2"
}
const _hoisted_14 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_15 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_16 = { class: "error-label" }
const _hoisted_17 = {
  key: 0,
  class: "ds-panel ds-panel--space_5xl"
}
const _hoisted_18 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_19 = {
  key: 0,
  class: "ds-panel ds-panel--space_5xl"
}
const _hoisted_20 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_21 = { class: "ds-panel ds-panel--space_5xl" }
const _hoisted_22 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_23 = { class: "g-row g-row--appearance_spaced g-row--space_xl g-row--justify_center" }
const _hoisted_24 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_25 = { class: "ds-panel ds-panel--space_lg" }
const _hoisted_26 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_27 = { class: "g-row g-row--justify_center" }
const _hoisted_28 = { class: "g-cell" }
const _hoisted_29 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.send }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.items, (item) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_VeeField, {
                      type: "radio",
                      name: "gateway",
                      rules: "required",
                      "unchecked-value": false,
                      modelValue: $setup.formdata.gateway,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.gateway) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("label", _hoisted_7, [
                          _withDirectives(_createElementVNode("input", {
                            class: "d-control-radio__element",
                            name: "gateway",
                            type: "radio",
                            value: item,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.gateway) = $event)),
                            onChange: $setup.gatewayChangeHandler
                          }, null, 40, _hoisted_8), [
                            [_vModelRadio, $setup.formdata.gateway]
                          ]),
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "d-control-radio__label" }, null, -1)),
                          _createElementVNode("span", _hoisted_9, [
                            _createElementVNode("p", _hoisted_10, _toDisplayString(item.label), 1),
                            _createElementVNode("span", _hoisted_11, [
                              _createElementVNode("span", _hoisted_12, [
                                ($setup.formdata.gateway.service === item.service)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(item.description), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ])
                ])
              ]))
            }), 256))
          ]),
          _createVNode(_component_VeeError, { name: "gateway" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(message), 1)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        (_openBlock(), _createBlock(_KeepAlive, null, [
          ($setup.formdata.gateway.service === 'requisites')
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode($setup["IndividualEntityForm"], {
                    formdata: $setup.formdata,
                    errors: errors
                  }, null, 8, ["formdata", "errors"])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 1024)),
        (_openBlock(), _createBlock(_KeepAlive, null, [
          ($setup.formdata.gateway.service === 'invoice')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode($setup["LegalEntityForm"], {
                    formdata: $setup.formdata,
                    errors: errors
                  }, null, 8, ["formdata", "errors"])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 1024)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_control_button, {
                  disabled: $setup.isLoad,
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Сплатити")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ])
            ])
          ]),
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("span", _hoisted_29, _toDisplayString(message), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}