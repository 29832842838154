import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_xl g-row--justify_center" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--5-xl g-cols--4-mac" }
const _hoisted_3 = { class: "ds-panel ds-panel--space_3xl" }
const _hoisted_4 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_5 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_6 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_7 = { class: "error-label" }
const _hoisted_8 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_9 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_10 = { class: "error-label" }
const _hoisted_11 = ["action"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.send }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_control_input, {
            name: "email",
            type: "email",
            rules: "required|email",
            modelValue: $setup.formdata.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.email) = $event)),
            errors: errors,
            "input-label": _ctx.$t("Е-mail"),
            placeholder: _ctx.$t("Введіть e-mail")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_control_button, {
                disabled: $setup.isLoad,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Продолжить")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ]),
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(message), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_VeeError, { name: "captcha" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("Captcha -")) + " " + _toDisplayString(message), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("form", {
        ref: "formElement",
        method: "post",
        action: $setup.props.url
      }, [
        _createElementVNode("input", {
          type: "hidden",
          name: "partial_token",
          value: $setup.formdata.partialToken
        }, null, 8, _hoisted_12),
        _createElementVNode("input", {
          type: "hidden",
          name: "email",
          value: $setup.formdata.email
        }, null, 8, _hoisted_13)
      ], 8, _hoisted_11)
    ]),
    _: 1
  }))
}