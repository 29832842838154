import { defineComponent as _defineComponent } from 'vue'
import { reactive, defineAsyncComponent } from 'vue'
import useLogin from '@/composables/useLogin'


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  props: {
  close: {
    type: Function,
    default: () => ({}),
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const PrivacyPolicy = defineAsyncComponent(() => import('@/components/Authentication/PrivacyPolicy'))
const SocialNetworks = defineAsyncComponent(() => import('@/components/Authentication/SocialNetworks'))

const props = __props

const { send, isLoad } = useLogin()
const formdata = reactive({})

const prepareFormdata = (sendData: object, control: any) => {
  send(formdata, control)
}

const __returned__ = { PrivacyPolicy, SocialNetworks, props, send, isLoad, formdata, prepareFormdata, reactive, defineAsyncComponent, get useLogin() { return useLogin } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})