import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { compileMessages } from '@aspectus/vue-trans'

export default {
  install(app: App<Element>): any {
    const dateTimeFormats = {}

    const i18nOptions = {
      ...compileMessages(),
      silentTranslationWarn: true,
      missingWarn: false,
      fallbackWarn: false,
      legacy: false,
      dateTimeFormats,
    }

    const i18n = createI18n(i18nOptions)

    app.config.globalProperties.$t = (e: any) => e

    app.use(i18n)
  }
}
