import { defineComponent as _defineComponent } from 'vue'
import { EffectFade, Autoplay } from 'swiper'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleFade',
  props: {
  loop: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const modules = [EffectFade, Autoplay]

const __returned__ = { props, modules, get EffectFade() { return EffectFade }, get Autoplay() { return Autoplay } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})