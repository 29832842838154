<template>
  <render />
</template>

<script setup lang="ts">
import { ref, useSlots, onMounted } from 'vue'
import { getCookie, setCookie } from '@utils/cookies'
import { partnersDots } from '../../events'

const slots = useSlots()

const isActive = ref(false)

const innerWidth = window.innerWidth
const checkIsMobile = () => 768 > innerWidth

const checkAsideState = () => {
  const isMobile = checkIsMobile()

  if (isMobile) return

  const asideState = getCookie('dinternal-aside')

  if (asideState === 'opened') {
    isActive.value = true
  }
}

const toggle = () => {
  const bodyEl = document.querySelector('body') as HTMLElement
  const isMobile = checkIsMobile()

  isActive.value = !isActive.value

  if (isMobile) {
    if (isActive.value) {
      bodyEl.classList.add('is-overflow')
    } else {
      bodyEl.classList.remove('is-overflow')
    }
  } else {
    if (isActive.value) {
      setCookie('dinternal-aside', 'opened')
    } else {
      setCookie('dinternal-aside', 'closed')
    }
  }
  console.log('toggle sidebar')
  if (window.location.href.indexOf("tests-center") > -1 && 768 < window.innerWidth) {
    window.location.reload()
  }
  // partnersDots(0)
}

onMounted(() => {
  checkAsideState()
})

const render = () => slots.default({
  isActive: isActive.value,
  toggle,
})
</script>