import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch, defineAsyncComponent, onMounted } from 'vue'
import { orderCreateResource } from '@/services/order.service'
import { locationsAreaListResource, locationsSettlementListResource } from '@/services/locations.service'
import { institutionTypeListResource } from '@/services/authenticate.service'
import { clearPhone, prettifyPhone } from '@/utils/transformers'
import { useUserStore } from '@/store/user'
import { useCartStore } from '@/store/cart'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { useShowModal } from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'View',
  props: {
  gateways: {
    type: Array,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const CheckoutProducts = defineAsyncComponent(() => import('@/components/Checkout/Products'))
const CheckoutUserInfo = defineAsyncComponent(() => import('@/components/Checkout/UserInfo'))
const CheckoutPayment = defineAsyncComponent(() => import('@/components/Checkout/Payment'))
const CheckoutSummary = defineAsyncComponent(() => import('@/components/Checkout/Summary'))
const Promocode = defineAsyncComponent(() => import('@/components/Checkout/Promocode'))

const props = __props

const { $t } = useGlobal()
const userStore = useUserStore()

const cartStore = useCartStore()

const isLoad = ref(false)
const isActive = ref(false)
// const orderButtonKey = ref(0)
const choices = reactive({
  area: [],
  settlement: [],
  institutionType: [],
})

const formdata = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  settlement: null,
  area: null,
  institutionType: null,
  institution: '',
  acceptPolicy: false,
  paymentGateway: { service: null },
})

const prefillUserInfo = (val: any) => {
  if (!window.isAuthenticated) return

  formdata.firstName = val.info.firstName
  formdata.lastName = val.info.lastName
  formdata.email = val.info.email

  let userPhone = val.info.phone

  if (userPhone) {
    userPhone = prettifyPhone(userPhone)
  }

  formdata.phone = userPhone

  formdata.institution = val.info.institution || ''
}

watch(() => userStore.getUserInfo, val => {
  prefillUserInfo(val)
}, { immediate: true, deep: true })

watch(() => cartStore.cartInfo, () => {
  setCartInfo()
}, { deep: true })

const setCartInfo = () => {
  redirectFromEmptyCheckout()

  // Need for fixing bug on ipad
  // orderButtonKey.value++
}

const refreshCartInfo = () => {
  // console.log('refreshCartInfo')
  cartStore.getCartData()
}

const redirectFromEmptyCheckout = () => {
  if (!cartStore.cartInfo.quantity && cartStore.cartInfo.isDeterminated) {
    window.location = window.indexPageUrl
  }
}

const toggleOverflow = () => {
  const element = document.querySelector('html') as any
  const { pageYOffset } = window

  if (isActive.value) {
    element.style.top = `-${pageYOffset}px`
    element.classList.add('is-overflow')
    // element.classList.add('is-checkout-active')
  } else {
    const scrollOffset = Math.abs(element.offsetTop)

    element.removeAttribute('style')
    element.classList.remove('is-overflow')
    // element.classList.remove('is-checkout-active')

    window.scrollTo({
      top: scrollOffset,
      behavior: 'auto',
    })
  }
}

const openOrder = () => {
  isActive.value = true

  toggleOverflow()
}

const hideOrder = () => {
  isActive.value = false

  toggleOverflow()
}

const getAreaList = async () => {
  const { data: { items } } = await locationsAreaListResource.execute()

  choices.area = items
}

const searchSettlement = async (val: string) => {
  if (val && val.length >= 2) {
    const formdataToSend = {
      ref: formdata.area.ref,
      query: val,
    }
    locationsSettlementListResource.execute({}, formdataToSend).then(res => {
      const { data: { item } } = res

      choices.settlement = item.settlements
    }).catch(e => {
      console.log(e)
    })
  } else {
    choices.settlement = []
  }
}

const areaChangeHandler = () => {
  formdata.settlement = null
  choices.settlement = []
}

const getInstitutionType = async () => {
  const { data: { item } } = await institutionTypeListResource.execute()

  choices.institutionType = item.choices
}

const prepareFormdata = () => {
  const preparedFormdata = JSON.parse(JSON.stringify(formdata))

  preparedFormdata.phone = clearPhone(preparedFormdata.phone)
  preparedFormdata.settlement = preparedFormdata.settlement.settlement
  preparedFormdata.area = preparedFormdata.area.id
  preparedFormdata.institutionType = preparedFormdata.institutionType.value

  preparedFormdata.paymentGateway = preparedFormdata.paymentGateway.value

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  orderCreateResource.execute({}, preparedFormdata).then(res => {
    const { data: { redirect } } = res

    window.location = redirect.location
  }).catch(async (e: object) => {
    const message = {
      title: $t('Виникла помилка'),
      text: $t('Перевірте будь ласка наявність помилок у формі'),
    }

    useShowModal(message)

    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

onMounted(() => {
  getAreaList()
  getInstitutionType()
})

const __returned__ = { CheckoutProducts, CheckoutUserInfo, CheckoutPayment, CheckoutSummary, Promocode, props, $t, userStore, cartStore, isLoad, isActive, choices, formdata, prefillUserInfo, setCartInfo, refreshCartInfo, redirectFromEmptyCheckout, toggleOverflow, openOrder, hideOrder, getAreaList, searchSettlement, areaChangeHandler, getInstitutionType, prepareFormdata, send, ref, reactive, watch, defineAsyncComponent, onMounted, get orderCreateResource() { return orderCreateResource }, get locationsAreaListResource() { return locationsAreaListResource }, get locationsSettlementListResource() { return locationsSettlementListResource }, get institutionTypeListResource() { return institutionTypeListResource }, get clearPhone() { return clearPhone }, get prettifyPhone() { return prettifyPhone }, get useUserStore() { return useUserStore }, get useCartStore() { return useCartStore }, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get useShowModal() { return useShowModal }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})