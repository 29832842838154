import { defineComponent as _defineComponent } from 'vue'
interface Props {
  id?: string,
  isRequired?: boolean,
  inputLabel: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Label',
  props: {
    id: {},
    isRequired: { type: Boolean },
    inputLabel: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})