import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useSlots } from 'vue'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { generateBase64FromUrl } from '@utils/transformers'

interface Props {
  files: string[],
  title: string,
}

interface File {
  fileData: string,
  fileName: {
    src: string,
    name: string,
  },
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ZipLoader',
  props: {
    files: {},
    title: {}
  },
  setup(__props: any) {

const slots = useSlots() as any

const props = __props

const download = async () => {
  const files: File[] = await generateBase64FromUrl(props.files)

  const zip = new JSZip()

  files.forEach((el: File) => {
    zip.file(el.fileName.name, el.fileData, { base64: true })
  })

  const title = props.title.replace(/ /g, '-')

  zip.generateAsync({ type: 'blob' }).then((content: string) => {
    saveAs(content, `${title}.zip`)
  })
}

const render = () => slots.default({
  download,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render, { ref: "content" }, null, 512))
}
}

})