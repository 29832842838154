<template>
  <svg width="22" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1_151)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 30C12 30 0.0214567 19.7854 0.999993 10C1.49999 5 5.49999 0 12 0C18 0 22.2738 4.17454 23 9.5C24.5 20.5 12 30 12 30Z" fill="#4897B9"/>
    <path d="M16.4461 4.27598C20.0864 6.36699 20.9342 11.0555 18.4014 15.7986C16.1751 19.9684 11.3149 21.7519 7.69317 19.6258C4.07146 17.4998 3.36443 11.5798 4.92087 8.17176C7.1811 3.22232 12.4248 1.96559 16.4461 4.27598" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1709 10.7586C17.2963 9.80365 17.0747 8.6399 16.362 7.8733L16.3514 7.86188C14.9827 6.30341 13.0816 6.20473 11.8779 6.23572C10.548 6.26916 9.59179 6.71525 8.81033 7.12302C8.12838 7.48185 7.51276 8.09839 7.2636 8.40421C7.10505 8.59912 6.83405 9.00199 6.97885 9.36408C7.09938 9.6642 7.54674 9.64463 7.71581 9.51659C7.80484 9.44917 7.89633 9.36321 7.99679 9.26882C8.08711 9.18396 8.18468 9.09229 8.29422 9.00117C9.32159 8.14079 10.2268 7.60255 12.3479 7.60255C14.469 7.60255 15.6193 8.93838 15.6193 10.1307C15.6193 11.323 15.0579 12.1312 14.0063 12.6588C13.4557 12.9514 12.8482 13.1184 12.2266 13.1481C12.2808 11.9933 12.3075 10.9014 12.3075 10.9014C12.3099 10.7643 12.3156 10.6316 12.3212 10.5049C12.3491 9.86318 12.3703 9.3772 11.9103 9.28253C11.3594 9.16917 10.3441 9.28253 10.3239 9.91375C10.2988 9.8216 10.4282 15.1095 10.4517 15.2147L10.4614 15.3354C10.4691 15.4634 10.5186 15.5853 10.6022 15.682C10.6932 15.7745 10.8133 15.8322 10.9419 15.8451C10.961 15.8477 10.9802 15.8491 10.9994 15.8492C11.1487 15.8463 11.2969 15.8235 11.4403 15.7815C11.6468 15.7337 11.8336 15.6226 11.975 15.4634C12.0458 15.3769 12.093 15.2731 12.1117 15.1625C12.1295 15.0654 12.1643 14.4505 12.174 14.2727C13.2895 14.2621 14.4237 14.0509 15.2869 13.5216C16.3749 12.8537 17.0293 11.8465 17.1709 10.7586ZM11.3998 16.4592C11.5315 16.4869 11.655 16.5449 11.7606 16.6288L11.7598 16.6304C11.9599 16.7874 12.0789 17.0273 12.0834 17.2829C12.0877 17.4952 11.9939 17.6974 11.8294 17.8301C11.6782 17.9495 11.4907 18.0126 11.2987 18.0087C11.2047 18.009 11.1111 17.9966 11.0204 17.972C10.8787 17.9367 10.7504 17.8601 10.6515 17.7518C10.5732 17.6539 10.5228 17.5363 10.5059 17.4117C10.4734 17.1955 10.5256 16.9749 10.6515 16.7968C10.7885 16.5853 11.0192 16.4545 11.2696 16.4461C11.3133 16.4464 11.3569 16.4508 11.3998 16.4592Z" fill="#4897B9"/>
    </g>
    <defs>
    <filter id="filter0_d_1_151" x="-9.05685" y="-8" width="42.1818" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0516613 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_151" result="shape"/>
    </filter>
    </defs>
  </svg>
</template>