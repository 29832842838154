<template>
  <render />
</template>

<script setup lang="ts">
import { ref, useSlots, watch } from 'vue'

interface Props {
  initialActive?: boolean,
}

const slots = useSlots()
const props = defineProps<Props>()

const isActive = ref(false)

const hide = () => {
  isActive.value = false
}

const show = () => {
  isActive.value = true
}

const toggle = () => {
  isActive.value = !isActive.value
}

watch(() => props.initialActive, () => {
  isActive.value = props.initialActive
})

const render = () => slots.default({
  isActive: isActive.value,
  hide,
  show,
  toggle,
})
</script>