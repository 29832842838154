import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive } from 'vue'
import { $vfm } from 'vue-final-modal'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { twoFactorConfirmResource, twoFactorRequestResource } from '@/services/2factor.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailSet',
  props: {
  partialToken: {
    type: String,
  },
  url: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const isLoad = ref(false)
const formdata = reactive({
  email: null,
  partialToken: props.partialToken,
  tokenId: null,
})
const formElement = ref(null)

const getConfirmationData = async () => {
  await recaptchaLoaded()

  const captchaToken = await executeRecaptcha()

  return {
    token: formdata.email,
    way: 'default',
    captcha: captchaToken,
  }
}

const send = async (sendData: object, control: any) => {
  if (formdata.tokenId) {
    confirmRegistration(formdata, control)
  } else {
    if (isLoad.value) return

    isLoad.value = true

    const confirmationData = await getConfirmationData()

    twoFactorRequestResource.execute({}, confirmationData).then(async res => {
      $vfm.show({
        component: 'UiModalContainer',
      }, {
        component: 'Modals/Authentication/EmailConfirm',
        info: {
          callback: confirmData => {
            formdata.tokenId = confirmData.id

            confirmRegistration(formdata, control)
          },
          resource: twoFactorConfirmResource,
          id: res.id,
          email: formdata.email,
        },
        resendInfo: {
          resource: twoFactorRequestResource,
          getData: getConfirmationData,
        },
      })
    }).catch(async (e: object) => {
      const parsed = await useErrorsGetter(e)

      useParsedSetter(parsed, control.setFieldError)
    }).finally(() => {
      isLoad.value = false
    })
  }
}

const confirmRegistration = async (sendData: object, control: any) => {
  if (isLoad.value) return

  isLoad.value = true

  const dataToSend = new FormData(formElement.value)

  dataToSend.append('token_id', sendData.tokenId)

  return fetch(props.url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    redirect: 'follow',
    body: JSON.stringify(Object.fromEntries(dataToSend)),
  }).then(response => {
    if (response.redirected) {
      window.location.href = response.url
    }
  }).catch(e => {
    console.warn(e)
  }).finally(() => {
    isLoad.value = false
  })
}

const __returned__ = { props, executeRecaptcha, recaptchaLoaded, isLoad, formdata, formElement, getConfirmationData, send, confirmRegistration, ref, reactive, get $vfm() { return $vfm }, get useReCaptcha() { return useReCaptcha }, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get twoFactorConfirmResource() { return twoFactorConfirmResource }, get twoFactorRequestResource() { return twoFactorRequestResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})