import useRoute from '@/router/utils'
import { LIST_URL } from './consts'
import { optionsT } from '@/router'

const List = () => import('./views/List.vue')

export const LIST = 'profile:certificate:list'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, {}),
  ]
}
