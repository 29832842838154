export const audioManager = {
  audioElements: [],
  currentPlaying: null,

  registerAudioElement(audioElement) {
    this.audioElements.push(audioElement)
  },

  playAudio(audioElement) {
    this.pauseAllAudio()

    this.currentPlaying = audioElement

    audioElement.play()
  },

  pauseAllAudio() {
    this.audioElements.forEach((audioElement) => {
      audioElement.pause()
    })

    this.currentPlaying = null
  },

  isPlaying(audioElement) {
    return audioElement === this.currentPlaying
  },
}